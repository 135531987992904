var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-card ticket-card-height"},[_c('div',{staticClass:"ticket-card-options"},[_c('div',{staticClass:"ticket-card-options__left is-pointer",on:{"click":function($event){return _vm.getTags('tags', 'status', {})},"mouseover":function($event){_vm.refresh = true},"mouseleave":function($event){_vm.refresh = false}}},[_c('sh-icon',{staticClass:"is-24x24 mr-5",attrs:{"name":'ticket-history-recents'}}),(_vm.refresh)?_c('div',{staticClass:"is-medium-12-500-14 has-opacity-5 has-text-danger"},[_vm._v(" "+_vm._s(_vm.localization("app-refresh", "Refresh"))+" ")]):_c('div',{staticClass:"is-medium-12-500-14 has-opacity-5"},[_vm._v(" "+_vm._s(_vm.since)+" mins ago ")])],1),_c('div',{staticClass:"ticket-card-options__right",class:[
        !_vm.eachCount || !Object.keys(_vm.eachCount).length ? 'has-no-access' : null
      ]},[_c('sh-icon',{staticClass:"is-24x24 mr-15 is-pointer is-hidden",attrs:{"name":'ticket-download'}}),_c('sh-icon',{staticClass:"is-24x24 is-pointer",attrs:{"name":'ticket-expand-maximize'},nativeOn:{"click":function($event){return _vm.$router.push({
            name: 'overview-tag',
            query: { filter: _vm.selected_filter },
            params: { filters: _vm.top_header_query }
          })}}})],1)]),_c('div',{staticClass:"ticket-card-heading"},[_c('div',{staticClass:"ticket-card-heading__icon"},[_c('sh-icon',{staticClass:"is-50x50",attrs:{"name":'ticket-card-tags'}})],1),_c('div',{staticClass:"ticket-card-heading__title "},[_vm._v(" "+_vm._s(_vm.localization("app-tags", "Tags"))+" ")]),_c('div',{staticClass:"ticket-card-heading__subtitle"},[_c('div',{staticClass:"ticket-dropdown",on:{"click":function($event){_vm.open_ticket_dropdown = !_vm.open_ticket_dropdown}}},[_c('span',{staticClass:"option-value is-medium-14-500-17"},[_vm._v(" "+_vm._s(_vm.localization( ("app-" + (_vm.selected_filter .split(" ") .join("-") .toLowerCase())), _vm.selected_filter ))+" ")]),(_vm.open_ticket_dropdown)?_c('sh-icon',{staticClass:"is-16x16 is-pointer",attrs:{"name":'expand'}}):_c('sh-icon',{staticClass:"is-16x16 is-pointer",attrs:{"name":'collapse'}}),(_vm.open_ticket_dropdown)?_c('ticket-dropdown',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.open_ticket_dropdown = false); }),expression:"() => (open_ticket_dropdown = false)"}],attrs:{"selected_filter":_vm.selected_filter,"dropdown_items":_vm.dropdown_items},on:{"filter_selection":function($event){$event == 'Each' ? (_vm.extract = true) : (_vm.extract = false);
            _vm.extract_tags();}}}):_vm._e()],1)])]),(_vm.is_loading)?_c('div',{staticClass:"loading"},[_c('img',{staticClass:"image is-64x64 margin-auto",attrs:{"src":require("@/assets/icons/ellipsis-animation.svg")}})]):(_vm.eachCount && Object.keys(_vm.eachCount).length)?_c('div',{staticClass:"ticket-card-tags"},_vm._l((_vm.sortedTags.slice(0, 5)),function(tag,i){return _c('div',{key:i,staticClass:"ticket-db-list"},[(_vm.eachCount[tag])?_c('div',{staticClass:"ticket-db-list__details"},[_c('div',{staticClass:"ticket-db-list__details__top is-medium-16-500-19"},[_c('div',{staticClass:"ticket-db-list__details__left tag-title"},[_c('span',{staticClass:"is-capitalized"},[_vm._v(_vm._s(_vm._f("truncate")(tag,24)))])]),_c('div',{staticClass:"ticket-db-list__details__right"},[_vm._v(" "+_vm._s((_vm.eachCount[tag].closed && _vm.eachCount[tag].closed.count) || 0)+"/"+_vm._s(_vm.eachCount[tag].total)+" ")])]),_c('div',{staticClass:"ticket-db-list__details__bottom"},[_c('ticket-progress',{attrs:{"total":_vm.eachCount[tag].total,"value":(_vm.eachCount[tag].closed && _vm.eachCount[tag].closed.count) || 0,"valueClass":'tag-progress-value'}})],1)]):_vm._e()])}),0):_c('div',[_c('p',{staticClass:"is-regular-14-500-17 has-text-centered has-opacity-8 py-20"},[_vm._v(" "+_vm._s(_vm.localization( "app-no-tickets-found-with-the-selected-filters", "No tickets found with the selected filters!" ))+" ")])]),_c('div',{staticClass:"ticket-card-showmore is-medium-14-500-17 is-flex align-center",class:[
      !_vm.eachCount || !Object.keys(_vm.eachCount).length
        ? 'has-no-access has-text-grey'
        : null
    ],on:{"click":function($event){return _vm.$router.push({
        name: 'overview-tag',
        query: { filter: _vm.selected_filter },
        params: { filters: _vm.top_header_query }
      })}}},[_c('span',[_vm._v(_vm._s(_vm.localization("app-show-more", "Show more")))]),(!_vm.eachCount || !Object.keys(_vm.eachCount).length)?_c('sh-icon',{staticClass:"is-12x12 mt-2 ml-5",attrs:{"name":'right-arrow-grey'}}):_c('img',{staticClass:"ml-5 mt-2",attrs:{"src":require("@/assets/icons/tickets/showmore-arrow.png"),"alt":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }