var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:" pt-20"},[_c('div',{staticClass:"ticket-overview-options"},[_c('div',{staticClass:"ticket-overview-options__left"},[_c('span',{staticClass:"is-flex align-center"},[_c('sh-icon',{staticClass:"is-16x16 mr-10 is-pointer",attrs:{"name":'ticket-header-back'},nativeOn:{"click":function($event){return _vm.$router.push({ name: 'schedules' })}}}),_c('span',{staticClass:"ticket-overview-options__left__title"},[_vm._v(" "+_vm._s(_vm.localization("app-schedules", "Schedules"))+" ")])],1)])]),_c('div',{staticClass:"tickets px-20"},[(_vm.selected_ticket)?_c('div',{staticClass:"ticket-list-item ",on:{"click":function($event){return _vm.open_ticket_details(_vm.selected_ticket.uid)}}},[_c('div',{staticClass:"mt-40 pt-20 mb-20"},[_c('div',{staticClass:"columns is-vcentered"},[_c('div',{staticClass:"column is-narrow is-paddingless is-relative",staticStyle:{"top":"-1.2rem"}},[_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":_vm.selected_ticket.bookmark ? 'bookmarked-highlight' : 'bookmark'}})],1),_c('div',{staticClass:"column is-3 "},[_c('div',{staticClass:"name-block-details"},[_c('div',{staticClass:"is-medium-16-500-19 no-whitespace-wrap is-flex is-vcenter"},[_c('p',{staticClass:"is-pointer has-text-dark mb-5"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                      _vm.selected_ticket.name && _vm.selected_ticket.name.length > 30
                        ? _vm.selected_ticket.name
                        : null
                    ),expression:"\n                      selected_ticket.name && selected_ticket.name.length > 30\n                        ? selected_ticket.name\n                        : null\n                    "}],staticClass:"ticket-name-text"},[_vm._v(_vm._s(_vm._f("truncate")(_vm.selected_ticket.name,30)))])])]),(_vm.selected_ticket.tags && _vm.selected_ticket.tags.length)?_c('div',[_c('assigned-tags',{attrs:{"write_access":_vm.has_write_access,"service":_vm.ticketService,"tags":_vm.selected_ticket.tags,"resourceId":_vm.selected_ticket.uid,"number_of_tags_to_display":2}})],1):_c('p',{staticClass:"is-regular-14-00-17 has-opacity-6 no-whitespace-wrap"},[_vm._v(" No associated tags ")])])]),_c('div',{staticClass:"column is-2"},[_c('p',{staticClass:"has-text-theme-black has-opacity-7 is-regular-14-500-17"},[_vm._v(" #of Tickets ")]),_c('p',{staticClass:"has-text-theme-black  is-semiBold-14-600-17 mt-3"},[_vm._v(" "+_vm._s(_vm.tickets_total_count)+" ")])]),_c('div',{staticClass:"column "},[_c('p',{staticClass:"has-text-theme-black has-opacity-7 is-regular-14-500-17 "},[_vm._v(" "+_vm._s(_vm.recurrence_text)+" ")]),_c('p',{staticClass:"has-text-theme-black   mt-3"},[_c('span',{staticClass:"is-medium-14-500-17 has-opacity-7"},[_vm._v("Next occurrence")]),_c('span',{staticClass:"is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.next_occurrence ? _vm.next_occurrence == "-" ? _vm.next_occurrence : _vm.next_occurrence == "0" ? _vm.localization("app-today", "Today") : _vm.next_occurrence == "1" ? _vm.localization("app-today", "Tomorrow") : ("in " + _vm.next_occurrence + " days") : _vm.localization("app-not-available", "*Not available"))+" ")])])]),_c('div',{staticClass:"column is-1 mr-30"}),_c('div',{staticClass:"column is-flex is-vcenter is-2"},[_c('ticket-progress',{attrs:{"total":(_vm.ticket.checklistsStats &&
                  _vm.ticket.checklistsStats.totalItems) ||
                  0,"value":(_vm.ticket.checklistsStats &&
                  _vm.ticket.checklistsStats.checkedItems) ||
                  0,"valueClass":'member-progress-value'}}),_c('div',{staticClass:"ml-10 is-medium-14-500-17 has-text-new-grey"},[_vm._v(" "+_vm._s(_vm.selected_ticket.checklistStats ? _vm.selected_ticket.checklistStats.checkedItems / _vm.selected_ticket.checklistStats.totalItems : 0)+"% ")])],1)])])]):_vm._e()]),_c('tickets-list',{attrs:{"extra_query":_vm.query}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }