var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.item)?_c('div',{staticClass:"columns list-card is-flex align-center"},[_c('div',{staticClass:"column",class:[_vm.$route.name.includes('asset-details') ? 'is-3' : 'is-4']},[_c('router-link',{attrs:{"to":{ name: 'therm-viewer', params: { id: _vm.item.uid } }},nativeOn:{"click":function($event){return _vm.append_recently_accessed_resource('therm', _vm.item.uid)}}},[_c('main-details-media',{attrs:{"truncate_value":36,"hideImage":true,"show_tooltip":true,"info":{
            name: _vm.item.name,
            name_class:
              'is-semiBold-16-600-19 has-text-theme-black card-list-title',
            subtext: _vm.localization('app-thermal-scanning', 'Thermal Scanning'),
            subtext_class:
              ' is-regular-14-500-17 has-text-theme-grey has-opacity-7',
            type: 'projectManagement'
          }}})],1)],1),(_vm.$route.name != 'dashboard')?_c('div',{staticClass:"column ",class:[_vm.$route.name.includes('asset-details') ? 'is-3' : 'is-2_5']},[_c('div',{staticClass:"is-flex is-vcenter"},[_c('user-avatar',{attrs:{"user":_vm.item.users.owner,"pxsize":30,"custom_class":'tiny-user-avatar',"show_tooltip":true}}),(_vm.item.users && _vm.item.users.owner)?_c('p',{directives:[{name:"username",rawName:"v-username:[15].truncate",value:(_vm.item.users.owner),expression:"item.users.owner",arg:15,modifiers:{"truncate":true}}],staticClass:"ml-5 is-medium-14-500-17 has-text-weight-semibold has-text-lightgrey is-capitalized"}):_vm._e()],1)]):_vm._e(),_c('div',{staticClass:"column is-flex is-center",class:[_vm.$route.name.includes('asset-details') ? 'is-1_5' : 'is-1']},[_c('h1',{staticClass:"is-medium-16-500-19 has-text-theme-black mt-5 affected"},[_vm._v(" "+_vm._s(( (_vm.item.metrics.modules.affected * (_vm.item.moduleWattPeak || 300)) / 1000 ).toFixed(1))+"KW ")])]),_c('div',{staticClass:"column is-flex is-center",class:[_vm.$route.name.includes('asset-details') ? 'is-1_5' : 'is-1_5']},[_c('h1',{staticClass:"is-medium-16-500-19 has-text-theme-black mt-5 capacity"},[_vm._v(" "+_vm._s(( _vm.item.metrics.modules.total * ((_vm.item.moduleWattPeak || 300) / 1000000) ).toFixed(1))+"MW ")])]),_c('div',{staticClass:"column is-1 is-flex is-center"},[_c('h1',{staticClass:"is-medium-16-500-19 has-text-theme-black mt-5"},[_vm._v(" "+_vm._s((_vm.item.metrics.issues && _vm.total_issues(_vm.item.metrics.issues)) || "0")+" ")])]),_c('div',{staticClass:"column is-2  is-flex is-end"},[_c('router-link',{staticClass:"is-pointer button  is-flex is-vcenter ml-30 blue-background",attrs:{"to":{ name: 'therm-view-details', params: { id: _vm.item.uid } }}},[_c('span',{staticClass:"is-medium-12-500-14 mr-5"},[_vm._v(" "+_vm._s(_vm.localization("app-report-dashboard", "Report Dashboard"))+" ")]),_c('i',{staticClass:"fas fa-chevron-right mt-1"})])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }