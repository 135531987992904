<template>
  <div
    class="columns is-mobile bb-1 has-text-centered has-background-white is-normal-14-500-17 has-text-theme-grey py-10 px-30"
    style="margin: 1px"
  >
    <!-- @click="update_sort_key('name')" -->
    <div class="column is-4 is-flex is-vcenter">
      <span class="is-pointer">
        {{ localization("app-schedules", "Schedules") }}
      </span>
      <!-- <span class="sort-indicators">
        <i class="fas fa-chevron-up fa-xs header-sort-icon" :class="[sorting_key === 'name' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
        <i class="fas fa-chevron-down fa-xs header-sort-icon" :class="[sorting_key === '-name' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
      </span> -->
    </div>
    <!-- @click="update_sort_key('assignee')" -->
    <div class="column is-2 is-flex is-vcenter">
      <span class="is-pointer">
        {{ localization("app-created-by", "Created by") }}
      </span>

      <!-- <span class="sort-indicators">
        <i class="fas fa-chevron-up fa-xs header-sort-icon" :class="[sorting_key === 'created_by' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
        <i class="fas fa-chevron-down fa-xs header-sort-icon" :class="[sorting_key === '-created_by' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
      </span> -->
    </div>
    <!-- @click="update_sort_key('duedate')" -->
    <div class="column is-flex is-vcenter is-2">
      <span class="is-pointer">{{
        localization("app-created-on", "Created on")
      }}</span>
      <!-- <span class="sort-indicators">
        <i class="fas fa-chevron-up fa-xs header-sort-icon" :class="[sorting_key == 'created_on' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
        <i class="fas fa-chevron-down fa-xs header-sort-icon" :class="[sorting_key == '-created_on' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
      </span> -->
    </div>

    <!-- @click="update_sort_key('priority')" -->
    <div class="column is-flex is-vcenter is-3">
      <span class="is-pointer ml-10">{{
        localization("app-tags", "Tags")
      }}</span>
      <!-- <span class="sort-indicators">
        <i class="fas fa-chevron-up fa-xs header-sort-icon" :class="[sorting_key === 'tags' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
        <i class="fas fa-chevron-down fa-xs header-sort-icon" :class="[sorting_key === '-tags' ? 'has-text-blue' : 'has-text-theme-grey']"></i>
      </span> -->
    </div>
  </div>
</template>
<script>
  export default {
    props: ["sorting_key"],
    methods: {
      update_sort_key(value) {
        let new_value;
        this.sorting_key == value
          ? (new_value = "-" + value)
          : (new_value = value);
        this.$emit("update_sort_key", new_value);
      }
    }
  };
</script>
<style lang="scss">
  .header-sort-icon {
    cursor: pointer;
    height: 4px;
    width: 12px;
    margin: -2px 3px;
    display: table;
    font-weight: 600;
    font-size: 8px;
  }
</style>
