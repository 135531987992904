<template>
  <div class="ticket-checklists">
    <div class="ticket-overview-options">
      <div class="ticket-overview-options__left">
        <span class="is-flex align-center">
          <sh-icon
            :name="'ticket-header-back'"
            v-if="$route.name == 'checklist-details'"
            @click.native="$router.push({ name: 'checklist' })"
            class="is-16x16 mr-10 is-pointer"
          />
          <span class="ticket-overview-options__left__title">{{
            localization("app-checklists", "Checklists")
          }}</span>
          <sort-dropdown
            class="ml-10"
            @sort="
              sort_by = $event;
              getData();
            "
          ></sort-dropdown>
        </span>
      </div>

      <div
        class="ticket-overview-options__right is-relative is-medium-14-500-17"
      >
        <span class="add-new-checklist" @click="activeModal = !activeModal">
          <sh-icon :name="'ticket-add-checklist'" class="is-12x12 mr-10" />
          <span class>{{
            localization("app-new-checklist", "New Checklist")
          }}</span>
        </span>
        <div
          class="new-checklist-card padding-20"
          v-if="activeModal"
          v-click-outside="() => (activeModal = false)"
        >
          <div class="new-checklist-card__header">
            <div
              class="is-pointer is-pulled-right"
              @click="activeModal = false"
            >
              <sh-icon :name="'ticket-create-modal-close'" class="is-14x14" />
            </div>
          </div>
          <div class="new-checklist-card__content">
            <div class="new-checklist-card__content__icon">
              <sh-icon :name="'ticket-create-checklist'" class="is-70x70" />
            </div>
            <input
              v-focus
              class="new-checklist-card__content__input"
              @keyup.enter="name ? create_checklist() : null"
              :placeholder="
                localization('app-checklist-name', 'Checklist Name')
              "
              type="text"
              v-model="name"
            />
            <div class="new-checklist-card__content__button">
              <span
                :class="{ 'is-disabled': !name }"
                class="new-btn is-pointer"
                @click="create_checklist"
              >
                <span class="is-semiBold-16-600-19"
                  >{{
                    localization(
                      "app-create-new-checklist",
                      "Create New Checklist"
                    )
                  }}
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns pt-5 px-20 is-flex is-vcenter">
      <div class="column is-flex is-vcenter">
        <searchbar @search="pageChangeHandle(1, $event)"></searchbar>
      </div>
    </div>
    <div v-if="is_loading">
      <div class="columns mt-25">
        <list-skeleton></list-skeleton>
      </div>
    </div>
    <div v-else-if="!is_loading && (!allChecklists || !allChecklists.length)">
      <no-results>
        <img
          slot="image"
          src="@/assets/illustrations/tickets-illustration.svg"
          alt
        />
      </no-results>
    </div>
    <div v-else class="mt-20">
      <checklist-header
        :sorting_key="sort_key"
        @update_sort_key="sort_key = $event"
      ></checklist-header>
      <div v-for="(checklist, index) in sorted" :key="checklist">
        <checklist-card
          @refresh_checklist_template="getData"
          :checklist="checklist"
          :index="index"
        ></checklist-card>
      </div>
    </div>
    <pagination
      :default_page_size="default_page_size"
      :current-page="currentPage"
      :page-count="pageCount"
      class="articles-list__pagination"
      @nextPage="pageChangeHandle('next')"
      @previousPage="pageChangeHandle('previous')"
      @loadPage="pageChangeHandle"
      :visiblePagesCount="visiblePagesCount"
      :totalRecords="totalRecords"
    ></pagination>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  const queryString = require("query-string");
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import ChecklistCard from "@/app/old/desktop/tickets/components/ticket-dashboard/components/checklists/components/checklist-card";
  import ListSkeleton from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/skeletons/list-skeleton.vue";
  import ChecklistHeader from "@/app/old/desktop/tickets/components/ticket-dashboard/components/checklists/components/checklist-header.vue";
  import searchbar from "@/app/old/desktop/core/shared/components/searchbar";
  import PaginationMixin from "@/app/old/desktop/shared/mixins/pagination.mixin.vue";
  import Pagination from "@/app/old/desktop/shared/components/pagination.vue";
  import SortDropdown from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/sort-dropdown.vue";

  export default {
    mixins: [PaginationMixin],
    components: {
      ChecklistCard,
      ListSkeleton,
      ChecklistHeader,
      searchbar,
      Pagination,
      SortDropdown
    },
    data() {
      return {
        sort_by: {},
        sort_key: "",
        default_page_size: 25,
        activeModal: false,
        is_loading: true,
        tasks: [],
        name: "",
        new_task: {
          name: "",
          isTemplate: true,
          internal: false,
          items: [],
          organization: this.$store.state.current_organization.uid
        }
      };
    },
    computed: {
      ...mapState("checklist", ["allChecklists", "template"]),
      sorted() {
        if (!this.sort_key) return this.allChecklists;
        let sort_by;
        let order = this.sort_key.startsWith("-") ? "desc" : "asc";
        let key = this.sort_key.startsWith("-")
          ? this.sort_key.slice(1, this.sort_key.length)
          : this.sort_key;
        switch (key) {
          case "name":
            sort_by = "name";
            break;
          case "createdAt":
            sort_by = "createdAt";
            break;
        }
        return _.orderBy(this.allChecklists, [sort_by], [order]);
      }
    },
    created() {
      this.$store.dispatch("checklist/initialize_service", ticketService);
      this.getData();
    },

    methods: {
      create_checklist() {
        this.activeModal = false;
        this.create_new_checklist();
      },
      async getData() {
        this.is_loading = true;
        let query = {
          pageSize: this.default_page_size,
          pageNumber: this.$route.query.page || 1,
          isTemplate: true,
          q: this.$route.query.q
        };
        if (this.sort_by.key)
          query["sort"] = (this.sort_by.order ? "-" : "") + this.sort_by.key;

        let { data, headers } = await ticketService.get_all_checklists({
          query: queryString.stringify(query)
        });

        this.updatePagination(headers["x-total-count"]);
        await this.$store.dispatch(
          "checklist/add_template_checklists",
          data.data
        );
        this.is_loading = false;
      },
      async create_new_checklist(task) {
        let checklist = { ...this.new_task, name: this.name };
        checklist.organization = this.$store.state.current_organization.uid;
        checklist.items = task ? [task] : [];
        await this.$store.dispatch("checklist/add_new_template_checklist", {
          body: checklist
        });
        this.$router.push({
          params: { id: this.template.uid },
          name: "checklist-details"
        });
      }
    }
  };
</script>

<style lang="scss">
  .ticket-checklists {
    &-items {
      display: flex;
      margin: 4rem 0 2rem 0;
    }
  }
  .ticket-overview-options {
    padding-top: 0.5rem;
    margin-bottom: 1.8rem;
  }

  .new-checklist-card {
    position: absolute;
    top: 5rem;
    right: 0rem;
    z-index: 3;
    width: 320px;
    height: 250px;
    border-radius: 10px;
    box-shadow: -10px 20px 50px 0 rgba(23, 30, 72, 0.2);
    background-color: white;
    &__content {
      margin-top: 2rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: space-between;
      &__icon {
        padding: 1rem;
        .is-70x70 {
          height: 70px;
          width: 70px;
        }
      }
      &__input {
        width: 80%;
        border: 0;
        padding-bottom: 1rem;
        color: #171e48;
        font-size: 1.6rem;
        border-bottom: 1px solid rgba(23, 30, 72, 0.1);
        background-color: #fafcff;
      }
      &__button {
        color: $primary;
        padding-top: 3rem;
        // padding: 2rem;
        .new-btn {
          width: 189px;
          height: 40px;
          padding: 1rem 1.5rem;
          border-radius: 6px;
          box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
          border: solid 1px #2585d3;
          background-color: white;
        }
      }
    }
  }
  .add-new-checklist {
    cursor: pointer;
    height: 3.6rem;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    border-radius: 4px;
    background-color: $white;
    color: $primary;
    margin-left: 2rem;
  }
</style>
