<template>
  <div
    class="template-item bb-1"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="columns has-full-width">
      <div
        class="column is-narrow is-pointer"
        v-tooltip="{
          content: localization('app-bookmark', 'Bookmark'),
          html: true
        }"
        @click.stop="update_ticket({ bookmark: !ticket.bookmark })"
        :class="{ 'is-vhidden': !hover && !ticket.bookmark }"
      >
        <sh-icon
          :name="ticket.bookmark ? 'bookmarked-highlight' : 'bookmark'"
          class="is-18x18 mt-3"
        />
      </div>
      <div
        class="column is-4"
        @click="
          $router.push({ name: 'template-details', params: { id: ticket.uid } })
        "
      >
        <div class="is-medium-16-500-19 mr-10 is-pointer">
          {{ ticket.name }}
        </div>
        <div class="is-flex align-center pt-5">
          <div class="is-flex align-center mr-15">
            <sh-icon
              :name="'ticket-checklists-attachments'"
              class="has-opacity-8 is-16x16 is-pointer mr-7"
            />
            <div class="mr-10 has-opacity-6">
              {{ ticket.attachmentsCount ? ticket.attachmentsCount : 0 }}
            </div>
          </div>
          <div class="is-medium-14-500-17 has-opacity-2">|</div>
          <div class="is-flex align-center ml-15">
            <sh-icon
              :name="'checkbox'"
              class="has-opacity-8 is-16x16 is-pointer mr-7"
            />
            <div class="mr-10 has-opacity-7 is-medium-14-500-17">
              {{
                ticket.checklistsStats.checkedItems
                  ? ticket.checklistsStats.checkedItems
                  : 0
              }}
              <span>/</span>
              {{
                ticket.checklistsStats.totalItems
                  ? ticket.checklistsStats.totalItems
                  : 0
              }}
            </div>
          </div>
        </div>
      </div>
      <div class="column is-2 is-flex align-center">
        <user-avatar
          :user="ticket.owner"
          :pxsize="24"
          :custom_class="'tiny-user-avatar'"
        ></user-avatar>
        <p
          class="is-medium-14-500-17 ml-8 truncate-text is-width-15"
          v-username="ticket.owner"
        ></p>
      </div>
      <div class="column is-2 is-medium-14-500-17 is-flex align-center">
        {{ ticket.createdAt | prettyDate }}
      </div>
      <div class="column is-3 is-flex align-center">
        <assigned-tags
          v-if="ticket.tags && ticket.tags.length"
          :number_of_tags_to_display="3"
          :resourceId="ticket.uid"
          :tags="ticket.tags"
          :write_access="false"
        />
        <div v-else class="is-flex align-center">
          <sh-icon :name="'tags'" class="is-20x20 mr-5 has-opacity-6"></sh-icon>
          <span class="is-regular-14-00-17 has-opacity-6">{{
            localization("app-no-tags-assigned", "*No tags assigned")
          }}</span>
        </div>
      </div>
      <div
        class="column is-narrow"
        v-if="hover"
        @click.stop="
          showDeleteModal = true;
          $modal.show(`delete-template-${ticket.uid}`);
        "
      >
        <sh-icon
          :name="'trash'"
          class="is-20x20 mr-5 mt-10 is-pointer has-opacity-6"
        ></sh-icon>
      </div>
    </div>
    <prompt-modal
      :name="`delete-template-${ticket.uid}`"
      v-if="showDeleteModal"
      :heading="ticket.name"
      :iconPath="require('@/assets/icons/prompt/delete.svg')"
      :footer="true"
      :cancelString="'Cancel'"
      :confirmString="'Delete'"
      @cancel="$modal.hide(`delete-template-${ticket.uid}`)"
      @confirm="delete_template()"
      @closed="$modal.hide(`delete-template-${ticket.uid}`)"
    >
      <div class="prompt-modal__content__text" slot="content">
        {{
          localization(
            "app-are-you-sure-you-want-to-delete-the-template?",
            "Are you sure you want to delete the Template ?"
          )
        }}
      </div>
    </prompt-modal>
  </div>
</template>

<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import AssignedTags from "@/app/old/desktop/shared/components/tags/type-tag/components/assigned-tags";
  import TicketProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-progress.vue";
  import PromptModal from "@/app/old/desktop/shared/components/prompt-modal";

  export default {
    props: ["ticket"],
    data() {
      return {
        hover: false,
        ticketService: ticketService,

        showDeleteModal: false
      };
    },
    components: {
      AssignedTags,
      TicketProgress,
      PromptModal
    },
    methods: {
      async update_ticket(value) {
        this.ticket.bookmark = value.bookmark;
        await ticketService
          .patch({
            id: this.ticket.uid,
            body: value
          })
          .then(() => {
            this.$emit("refresh_template_tickets");
          })
          .catch(err => {
            console.log(err);
          });
      },
      async delete_template() {
        await ticketService
          .delete({
            id: this.ticket.uid
          })
          .catch(err => this.handleErrors(err, true));
        this.$emit("refresh_template_tickets");

        this.$modal.hide(`delete-template-${this.ticket.uid}`);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .template-item {
    padding: 1.5rem 6rem 1.5rem 3rem;
    background-color: $white;
    &:hover {
      background-color: $hover-new;
    }
  }
</style>
