<template>
  <div class="ticket-checklists is-relative">
    <div class="is-flex has-space-between padding-20">
      <div class="ticket-overview-options__left">
        <span class="is-flex align-center">
          <sh-icon
            :name="'ticket-header-back'"
            v-if="$route.name == 'schedule-details'"
            @click.native="$router.push({ name: 'schedules' })"
            class="is-16x16 mr-10 is-pointer"
          />

          <span class="is-medium-16-500-19">{{
            localization("app-schedules", "Schedules")
          }}</span>
        </span>
      </div>
      <div
        class="button is-info is-outlined has-background-white has-text-info is-large"
      >
        <span class="is-flex is-vcenter" @click="activeModal = true">
          <sh-icon :name="'ticket-add-checklist'" class="is-12x12 mr-10" />
          <span class="">{{
            localization("app-new-schedule", "New Schedule")
          }}</span>
        </span>
      </div>
    </div>

    <div class="columns pt-5 px-20 is-flex is-vcenter">
      <div class="column is-flex is-vcenter">
        <searchbar @search="searchQuery = $event"></searchbar>
      </div>
    </div>
    <div
      class="new-checklist-card padding-20 "
      v-if="activeModal"
      v-click-outside="() => ((activeModal = false), (form.name = ''))"
    >
      <div class="new-checklist-card__header">
        <div
          class="is-pointer is-pulled-right"
          @click="
            activeModal = false;
            form.name = '';
          "
        >
          <sh-icon :name="'ticket-create-modal-close'" class="is-16x16" />
        </div>
      </div>
      <div class="new-checklist-card__content">
        <div class="new-checklist-card__content__icon">
          <sh-icon :name="'ticket-create-checklist'" class="is-70x70" />
        </div>
        <input
          v-focus
          class="new-checklist-card__content__input"
          :placeholder="localization('app-ticket-name', 'Ticket name')"
          type="text"
          v-model="form.name"
          @keyup.enter="create_schedule"
        />
        <div class="new-checklist-card__content__button">
          <span
            :class="{ 'is-disabled': !form.name }"
            class="new-btn is-pointer"
            @click="create_schedule"
          >
            <span class="is-semiBold-16-600-19">{{
              localization("app-create-new-schedule", "Create New Schedule")
            }}</span>
          </span>
        </div>
      </div>
    </div>
    <div v-if="is_loading">
      <div class="columns mt-30">
        <list-skeleton></list-skeleton>
      </div>
    </div>
    <div v-else>
      <div v-if="schedule_groups">
        <div v-for="(tickets, key, index) in schedule_groups" :key="key">
          <schedule-block
            @deleted-schedule="remove_schedule"
            :tickets="tickets"
            :index="index"
            :when="key"
            v-if="
              !filterDate ||
                filterDate == 'All Time' ||
                filterDate == schedule_times(key)
            "
          ></schedule-block>
        </div>
      </div>
      <no-results v-else></no-results>
    </div>
  </div>
</template>

<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import ScheduleBlock from "./components/schedule-block";
  import { mapState, mapMutations } from "vuex";
  import elementMixin from "@/app/old/desktop/shared/mixins/element.mixin";
  import TicketDetailsMixin from "@/app/old/desktop/shared/mixins/ticket-details.mixin.vue";
  import { RRule } from "rrule";
  import ListSkeleton from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/skeletons/list-skeleton.vue";
  import searchbar from "@/app/old/desktop/core/shared/components/searchbar";

  export default {
    mixins: [elementMixin, TicketDetailsMixin],
    components: {
      ScheduleBlock,
      ListSkeleton,
      searchbar
    },
    data() {
      return {
        searchQuery: "",
        is_loading: true,
        scheduledTickets: null,
        activeModal: false,
        filterDate: null,
        name: null,
        is_creating: false,
        form: {
          name: null
        }
      };
    },
    created() {
      this.get_schdules();
    },
    computed: {
      filtered_schedules() {
        if (
          this.scheduledTickets &&
          this.scheduledTickets.length &&
          this.searchQuery &&
          this.searchQuery.length
        ) {
          return this.scheduledTickets.filter(schedule =>
            schedule.name.includes(this.searchQuery)
          );
        } else {
          return this.scheduledTickets;
        }
      },
      schedule_groups() {
        if (this.filtered_schedules && this.filtered_schedules.length) {
          return _.groupBy(this.filtered_schedules, "scheduleFrequency");
        } else {
          return null;
        }
      },

      ...mapState("tickets", ["is_internal"]),
      ...mapMutations("tickets", ["ADD_NEW_TICKET"])
    },
    methods: {
      remove_schedule(uid) {
        this.scheduledTickets = _.remove(
          this.scheduledTickets,
          ticket => uid != ticket.uid
        );
      },
      async get_schdules() {
        this.is_loading = true;
        let { data } = await ticketService
          .getAll({
            query: `isRecurring=true&pageSize=1000&q=${this.searchQuery}`
          })
          .catch(err => this.handleErrors(err, true));
        this.scheduledTickets = data;
        this.scheduledTickets.map(r => {
          let parsed =
            r.schedule && r.schedule.rrule
              ? new RRule(RRule.parseString(r.schedule.rrule))
              : null;

          return (r["scheduleFrequency"] = parsed ? parsed.options.freq : "");
        });
        this.is_loading = false;
      },

      async create_schedule() {
        if (this.form.name) {
          this.form.name = this.form.name.trim();
        }

        if (this.is_creating || !this.form.name) return;
        this.is_creating = true;
        let element;

        if (this.element) {
          await this.create_or_get_element(this.element);
          element = this.get_target_element(this.element);
        } else {
          let org_element = await this.get_org_element();
          element = this.get_target_element(org_element);
        }
        if (!element) return;

        this.form.targetElement = element;

        this.form.internal = this.is_internal;

        if (this.element && this.element.centroid)
          this.form["location"] = {
            type: "Point",
            coordinates: Object.values(this.element.centroid)
          };

        let new_ticket_data = { ...this.form };

        let { data } = await ticketService
          .post({ body: new_ticket_data })
          .catch(err => {
            this.handleErrors(err);
            this.$toast.show(
              "Oops! Something went wrong",
              "",
              this.$store.state.izitoast_options.appError
            );
          });

        data.writeAccess = true;
        this.$store.commit("tickets/ADD_NEW_TICKET", data);
        this.activeModal = false;
        this.open_ticket_details(data.uid);
        this.is_creating = false;
        this.form.name = null;
      },

      schedule_times(date) {
        let scheduleKey;
        switch (date) {
          case "0":
            scheduleKey = "Yearly";
            break;
          case "1":
            scheduleKey = "Monthly";
            break;
          case "2":
            scheduleKey = "Weekly";
            break;
          case "3":
            scheduleKey = "Daily";
            break;

          default:
            break;
        }
        return scheduleKey;
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ticket-checklists {
    &-items {
      display: flex;
      margin: 4rem 0 2rem 0;
    }

    &-item {
      display: grid;
      grid-gap: 2rem;
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .ticket-checklist-card {
    background-color: #fff;
    border-radius: 1rem;
    box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
    padding: 2rem 2rem 2rem 1.5rem;
    display: flex;

    &__right {
      width: 100%;
    }

    &__top {
      display: flex;
      align-items: center;
    }
    &__content {
      &__desc {
        font-family: Barlow-Medium !important;
        font-size: 14px !important;
        font-weight: 500 !important;
      }
      &-sec {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 1rem;
        &__left {
          height: 3.6rem;
          width: 3.6rem;
          display: flex;
          img {
            margin-right: 0.5rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
</style>
