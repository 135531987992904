<template>
  <div class="highchart-chart">
    <div class="is-flex is-vcenter">
      <div class="is-medium-16-500-19">
        {{ card.title }}
      </div>
      <div class="is-flex is-vcenter report-options mr-20">
        <sh-icon
          :name="'ticket-expand-maximize'"
          class="is-24x24 mr-10 is-pointer"
          @click.native="openFullscreen"
        />
        <div class="options-dropdown" v-click-outside="() => (active = false)">
          <sh-icon
            @click.native="active = !active"
            :name="'3-dots-menu'"
            class="is-16x16 is-pointer"
          />
          <div class="options-dropdown--options" v-if="active">
            <p
              class="is-regular-16-500-19 is-pointer has-text-centered is-flex is-vcenter pl-20"
              @click="edit_report_popup"
            >
              <sh-icon
                @click.native="active = !active"
                :name="'edit-box'"
                class="is-16x16 mr-15"
              />
              <span>{{ localization("app-edit", "Edit") }}</span>
            </p>
            <p
              class="is-regular-16-500-19 is-pointer has-text-centered is-flex is-vcenter pl-20"
              @click="$emit('delete-report')"
            >
              <sh-icon
                @click.native="active = !active"
                :name="'delete-2'"
                class="is-16x16 mr-15"
              />
              <span>{{ localization("app-delete", "Delete") }}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="is_loading"
      class="padding-20 is-flex is-center is-vcenter"
      style="width: 100%; margin-top: 20%"
    >
      <img
        class="image is-64x64 is-relative loading-icon"
        src="@/assets/icons/ellipsis-animation.svg"
      />
    </div>
    <div v-if="!is_loading && (!report || !report.length)">
      <img
        class="image is-256x256 is-relative margin-auto pt-50"
        src="@/assets/illustrations/no-report-illustration.svg"
      />
      <p class="is-regular-14-500-17 has-opacity-7 has-text-centered mt-20">
        {{
          localization(
            "app-no-data-available-for-selected-values",
            "No data available for selected values"
          )
        }}
      </p>
    </div>
    <div
      v-show="!is_loading && report && report.length"
      :id="'chart-' + index"
    ></div>
  </div>
</template>
<script>
  import { loadScript } from "@/utils/loadScript.js";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import { mapState } from "vuex";
  import CreateReportComponent from "./create-report-popup";
  const stc = require("string-to-color");

  export default {
    props: ["card", "index", "width"],
    components: {
      CreateReportComponent
    },
    data() {
      return {
        max: null,
        chart_width: null,
        is_loading: false,
        is_fullscreen: false,
        hovertemplate: "",

        active: false,
        series: null,
        report: null,
        categories: null,
        status_colors: {
          pending: "#f79c4b",
          inprogress: "#08aa4f",
          resolved: "#5240b9",
          closed: "#b9bbc7",
          rejected: "#f85859"
        },
        priority_colors: {
          critical: "#fc5a5a",
          medium: "#08aa4f",
          low: "#747891",
          high: "#ffaf3b"
        },
        dtick_value: 1
      };
    },
    created() {
      this.getReport();
    },
    mounted() {
      loadScript(
        "https://cdnjs.cloudflare.com/ajax/libs/plotly.js/1.51.1/plotly.min.js",
        this.getData,
        "plotly-cdn"
      );
      let width = document.getElementsByClassName("vue-grid-layout")[0]
        .offsetWidth;
      let substract = 140;
      this.chart_width = (width - substract) / 3;

      document
        .getElementById(`chart-${this.index}`)
        .addEventListener("fullscreenchange", e => {
          if (document.fullscreenElement) {
          } else {
            this.is_fullscreen = false;
            this.plot_chart(this.card.type_of_graph);
          }
        });
    },
    watch: {
      card(new_val, old_val) {
        let is_same = JSON.stringify(new_val) === JSON.stringify(old_val);
        if (!is_same) {
          this.getReport();
        }
      },
      selected_asset(new_val, old_val) {
        this.getReport();
      },

      width(new_val, old_val) {
        this.plot_chart(this.card.type_of_graph);
      }
    },

    computed: {
      ...mapState(["TAGS"])
    },

    methods: {
      async getReport() {
        this.is_loading = true;
        if (this.card.filters && this.card.filters.duration) {
          // 1. Convert duration into duedate and startdate
          let endDate;
          let startDate;
          switch (this.card.filters.duration) {
            case "Today":
              startDate = new Date().toISOString();
              break;
            case "Tomorrow":
              endDate = moment().add(1, "days");
              startDate = new Date().toISOString();
              break;
            case "Yesterday":
              startDate = moment().subtract(1, "days");
              endDate = new Date().toISOString();
              break;
            case "Last week":
              startDate = moment().subtract(7, "days");
              endDate = new Date().toISOString();
              break;
            case "Last month":
              startDate = moment().subtract(30, "days");
              endDate = new Date().toISOString();
              break;
            case "Last year":
              startDate = moment().subtract(365, "days");
              endDate = new Date().toISOString();
              break;
            case "Custom":
              startDate = this.card.filters.dueDateStart;
              endDate = this.card.filters.dueDateEnd;
              break;

            default:
              startDate = null;
              endDate = null;
              break;
          }
          // 2.  add dueDate
          this.card.filters["dueDateStart"] = startDate
            ? new Date(startDate).toISOString()
            : null;
          this.card.filters["dueDateEnd"] = endDate
            ? new Date(endDate).toISOString()
            : null;
          // delete this.card.filters.duration;
          // 3. Proceed
        }
        let body = {
          filters: this.card.filters,
          x: {
            key: this.card.xAxis,
            extract: this.card.xAxis == "tags" ? true : false
          },
          y: {
            key: this.card.yAxis,
            extract: this.card.yAxis == "tags" ? true : false
          }
        };

        let res = await ticketService.get_report({ body }).catch(err => {
          this.handleErrors(err);
        });
        this.report = _.compact(
          res.map(r => {
            if (r.x && r.x.length) return r;
          })
        );
        this.is_loading = false;
        this.add_graph_settings();
      },
      add_graph_settings() {
        if (this.report.length == 1 && this.report[0].x == null) {
          this.categories = [];
          this.series = [];
        } else {
          //Setting series & categories

          if (this.card.type_of_graph == "Pie chart") {
            this.series = this.report.map(e => _.sumBy(e.y, "count"));
            this.categories = this.report.map(a =>
              this.localization(`app-${a.x}`, a.x)
            );
          } else {
            this.categories = this.report.map(a =>
              this.series_value_name(
                "xAxis",
                this.localization(`app-${a.x}`, a.x)
              )
            );
            const anotherEmptyObject = {};
            const all_keys = _.uniq(
              _.flatten(this.report.map(r => r.y)).map(a => a["type"])
            );
            all_keys.map(key => (anotherEmptyObject[key] = []));
            let report_array = this.report.map(r => r.y);

            for (let array in report_array) {
              report_array[array] = all_keys.map(e =>
                _.find(report_array[array], o => {
                  return o.type == e;
                })
              );
            }

            for (let array in report_array) {
              for (let i in anotherEmptyObject) {
                let data = _.find(report_array[array], o => o && o.type == i);
                if (data) {
                  anotherEmptyObject[i].push(data.count);
                } else {
                  anotherEmptyObject[i].push(null);
                }
              }
            }

            let colors = { ...this.status_colors, ...this.priority_colors };

            this.series = Object.keys(anotherEmptyObject).map(key => {
              return {
                name: this.series_value_name("yAxis", key),

                data: anotherEmptyObject[key],
                color: colors[key] ? colors[key] : stc(key)
              };
            });
            this.max = _.max(_.flatten(this.series.map(a => a.data)));
            let dtick_values = [1, 10, 100, 1000, 10000, 100000];
            this.dtick_value = dtick_values[this.max.toString().length - 1];
          }
        }
        this.plot_chart(this.card.type_of_graph);
      },
      plot_chart(graph_type) {
        if (graph_type == "Horizontal Bar") {
          if (!this.card.is_grouped) {
            this.hovertemplate = "%{x}<br>" + "<extra></extra> ";
          } else {
            this.hovertemplate =
              "%{meta}<br>" +
              `<b>${this.localization(
                "app-count-of-tickets",
                "# of tickets"
              )}</b>: %{x}<br>` +
              "<extra></extra> ";
          }
        } else {
          if (!this.card.is_grouped) {
            this.hovertemplate =
              `${this.localization("app-count-of-tickets", "# of tickets")}: ` +
              "<b>%{y}</b>" +
              "<extra></extra> ";
          } else {
            this.hovertemplate =
              "%{meta}<br>" +
              `<b>${this.localization(
                "app-count-of-tickets",
                "# of tickets"
              )}</b>: %{y}<br> ` +
              "<extra></extra> ";
          }
        }
        if (graph_type == "Horizontal Bar") {
          this.horizontal_chart();
        } else if (graph_type == "Vertical Bar") {
          this.vertical_chart();
        } else if (graph_type == "Scatter") {
          this.scatter();
        } else if (graph_type == "Line chart") {
          this.line_chart();
        } else if (graph_type == "Pie chart") {
          this.pie_chart();
        }
      },

      vertical_chart() {
        let data = this.series.map(item => {
          return {
            x: this.categories,
            y: item.data,
            name: item.name,
            meta: item.name,
            width: this.categories.map(a => 0.2),

            type: "bar",
            hovertemplate: this.hovertemplate,
            marker: {
              color: item.color,
              line: {
                width: 0.5
              }
            }
          };
        });

        var layout = {
          width: this.is_fullscreen ? null : this.chart_width * this.width,
          height: 400,
          barmode: "stack",
          showlegend: false,
          autosize: true,
          hovermode: "closest",
          font: { size: 11, family: "Barlow-Medium" },
          hoverlabel: { bgcolor: "#FFF", size: 11, family: "Barlow-Medium" },
          xaxis: {
            automargin: true,
            dtick: 1
          },
          yaxis: {
            automargin: true,
            dtick: this.dtick_value
          }
        };

        Plotly.newPlot(`chart-${this.index}`, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },
      horizontal_chart() {
        let data = this.series.map(item => {
          return {
            x: item.data,
            y: this.categories,
            name: item.name,
            meta: item.name,
            type: "bar",
            hovertemplate: this.hovertemplate,
            marker: {
              color: item.color,
              line: {
                width: 0.5
              }
            },
            orientation: "h"
          };
        });

        var layout = {
          width: this.is_fullscreen ? null : this.chart_width * this.width,
          height: 400,
          barmode: "stack",
          showlegend: false,
          hovermode: "closest",

          font: { size: 11, family: "Barlow-Medium" },
          hoverlabel: { bgcolor: "#FFF" },
          xaxis: {
            dtick: this.dtick_value,
            automargin: true
          },
          autosize: true,

          yaxis: {
            automargin: true,
            dtick: 1
          }
        };

        Plotly.newPlot(`chart-${this.index}`, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },
      line_chart() {
        let data = this.series.map(item => {
          return {
            x: this.categories,
            y: item.data,
            name: item.name,
            meta: item.name,
            type: "scatter",
            hovertemplate: this.hovertemplate,
            marker: {
              color: item.color,
              line: {
                width: 0.5
              }
            },
            mode: "lines+markers"
          };
        });

        var layout = {
          width: this.is_fullscreen ? null : this.chart_width * this.width,
          height: 400,
          barmode: "stack",
          showlegend: false,
          autosize: true,
          hovermode: "closest",

          font: { size: 11, family: "Barlow-Medium" },
          hoverlabel: { bgcolor: "#FFF" },
          xaxis: {
            dtick: 1,
            automargin: true
          },
          yaxis: {
            automargin: true,
            dtick: this.dtick_value
          }
        };

        Plotly.newPlot(`chart-${this.index}`, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },
      scatter() {
        let data = this.series.map(item => {
          return {
            x: this.categories,
            y: item.data,
            name: item.name,
            meta: item.name,
            type: "scatter",
            mode: "markers",
            hovertemplate: this.hovertemplate,
            marker: {
              color: item.color,
              line: {
                width: 0.5
              }
            }
          };
        });

        var layout = {
          width: this.is_fullscreen ? null : this.chart_width * this.width,
          height: 400,
          barmode: "stack",
          showlegend: false,
          autosize: true,
          hovermode: "closest",

          font: { size: 11, family: "Barlow-Medium" },
          hoverlabel: { bgcolor: "#FFF" },
          xaxis: {
            dtick: 1,
            automargin: true
          },
          yaxis: {
            automargin: true,
            dtick: this.dtick_value
          }
        };

        Plotly.newPlot(`chart-${this.index}`, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },
      pie_chart() {
        let colors = { ...this.status_colors, ...this.priority_colors };

        let data = [
          {
            values: this.series,
            labels: this.categories.map(a =>
              this.series_value_name("xAxis", a)
            ),
            // hovertemplate: this.hovertemplate,
            type: "pie",
            marker: {
              colors: this.categories.map(c =>
                ["status", "priority"].includes(this.card.xAxis)
                  ? colors[c]
                  : stc(c)
              ),

              line: {
                width: 0.5
              }
            },
            automargin: true
          }
        ];

        console.log("Pie", data);

        var layout = {
          height: this.is_fullscreen ? null : this.width == 3 ? 350 : 300,
          width: this.is_fullscreen ? null : this.width == 3 ? 350 : 300,
          showlegend: false,
          autosize: true,
          hovermode: "closest",
          margin: { t: this.width == 3 ? 20 : 70, b: 10, l: 0, r: 0 },

          font: { size: 11, family: "Barlow-Medium" },
          hoverlabel: { bgcolor: "#FFF" },
          yaxis: {
            automargin: true
          },
          xaxis: {
            automargin: true
          }
        };

        Plotly.newPlot(`chart-${this.index}`, data, layout, {
          displayModeBar: false,
          responsive: true
        });
      },

      edit_report_popup() {
        this.$modal.show(
          CreateReportComponent,
          {
            existing_card: this.card,
            update_report_filter: body => {
              let new_chart = {
                x: 0,
                y: 0,
                w: 2,
                h: 18,
                i: this.index,
                report: body,
                moved: false
              };
              this.$emit("update-card", body);
            }
          },
          {
            width: "48%",
            scrollable: false,
            height: "auto"
          }
        );
      },
      generate_user_name(val) {
        return this.$store.getters.state_users[val] &&
          this.$store.getters.state_users[val].first_name
          ? `${this.$store.getters.state_users[val].first_name} ${this.$store.getters.state_users[val].last_name} `
          : this.$store.getters.state_users[val] &&
            this.$store.getters.state_users[val].email
          ? this.$store.getters.state_users[val].email
          : this.$store.getters.state_users[val]
          ? this.$store.getters.state_users[val]
          : this.$store.state.internal_users_uids.includes(val)
          ? "SenseHawk"
          : "Unknown";
      },
      generate_tag_name(val) {
        if (val && Array.isArray(val) && val.length) {
          return _.flatten(
            val.map(a => {
              if (this.TAGS && this.TAGS[a]) {
                return this.TAGS[a].name ? this.TAGS[a].name : a;
              } else {
                return a;
              }
            })
          );
        } else {
          if (val.length) {
            this.TAGS[val];
            if (this.TAGS && this.TAGS[val]) {
              return this.TAGS[val].name ? this.TAGS[val].name : a;
            } else {
              return val;
            }
          } else {
            return "Unassigned";
          }
        }
      },
      series_value_name(axis, key) {
        if (key && key !== "null") {
          return this.card[axis] == "assignee" || this.card[axis] == "owner"
            ? this.generate_user_name(key)
            : this.card[axis] == "tags"
            ? this.generate_tag_name(key)
            : key == "inprogress"
            ? this.localization("app-in-progress", "In progress")
            : _.capitalize(this.localization(`app-${key}`, key));
        } else {
          return "Unassigned";
        }
      },
      openFullscreen(gd) {
        let elem = document.getElementById(`chart-${this.index}`);
        this.is_fullscreen = true;
        this.plot_chart(this.card.type_of_graph);
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          /* Safari */
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          /* IE11 */
          elem.msRequestFullscreen();
        }
      }
    }
  };
</script>
<style lang="scss">
  .plot-container {
    .svg-container {
      margin: auto !important;
    }
  }
  .options-dropdown {
    position: relative;
    &--options {
      width: 13rem;
      height: 9.6rem;
      border-radius: 1rem;
      box-shadow: 10px 20px 50px 0 rgba(23, 30, 72, 0.2);
      border: solid 1px rgba(23, 30, 72, 0.15);
      background-color: $white;
      position: absolute;
      right: 0;
      top: 2rem;
      z-index: 10;
      :hover {
        background: $pale-white-background;
      }
      p {
        height: calc(9.6rem / 2);
      }
    }
  }
  .hovertext {
    text-transform: capitalize;
  }
</style>
