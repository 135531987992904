<script>
  import { ticketService } from "@/app/old/desktop/shared/services/";

  export default {
    data() {
      return {
        lastFetchedAt: null,
        now: new Date(),
        refresh: false
      };
    },
    created() {
      setInterval(() => {
        this.now = Date.now();
      }, 60000);
    },
    computed: {
      since() {
        var a = moment(this.lastFetchedAt);
        var b = moment(this.now);
        return b.diff(a, "minutes");
      }
    },

    methods: {
      async getReport(xkey, ykey, filter = {}) {
        this.lastFetchedAt = new Date().toISOString();
        let allFilters = {
          ...{ organization: this.$store.state.current_organization.uid },
          ...filter,
          ...(this.top_header_query || {})
        };
        let body = {
          filters: allFilters,
          x: {
            key: xkey
          },
          y: {
            key: ykey
          }
        };
        return await ticketService.get_report({ body }).catch(err => {
          this.handleErrors(err);
        });
      },
      create_filter_obj() {
        this.asset_filter = { assetUid: this.selected_asset };

        let filtersData = _.pickBy(
          this.allFiltersData,
          v => v !== null && v !== undefined
        );
        if (
          this.allFiltersData &&
          this.allFiltersData.options &&
          this.allFiltersData.options.length
        ) {
          filtersData["bookmarked"] = this.allFiltersData.options.includes(
            "bookmarked"
          );
          filtersData["archived"] = this.allFiltersData.options.includes(
            "archived"
          );
          delete filtersData.options;
        }
        if (
          this.allFiltersData &&
          this.allFiltersData.dueDate &&
          this.allFiltersData.dueDate.length
        ) {
          filtersData["dueDateStart"] = this.allFiltersData.dueDate[0];
          filtersData["dueDateEnd"] = this.allFiltersData.dueDate[0];
          delete filtersData.dueDate;
        }
        if (this.allFiltersData && this.allFiltersData.assignee) {
          filtersData["takenUp"] = this.allFiltersData.assignee.map(a => a.uid);
          delete filtersData.assignee;
        }
        if (this.allFiltersData && this.allFiltersData.owner) {
          filtersData["owner"] = this.allFiltersData.owner.map(a => a.uid);
        }
        let show_filter = {};
        this.filters.forEach(filter => {
          if (filter.active) {
            show_filter[filter.key] = filter.value;
          } else {
            delete show_filter[filter.key];
          }
        });

        let newObj = {
          ...this.asset_filter,
          ...filtersData,
          ...show_filter
        };

        let filtersObj = Object.assign({}, newObj);
        return filtersObj;
      }
    }
  };
</script>
