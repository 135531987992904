<template>
  <div class="ticket-priority-expand">
    <div class="pt-10 mr-10">
      <div class="ticket-overview-options">
        <div class="ticket-overview-options__left">
          <span class="is-flex align-center">
            <sh-icon
              @click.native="$router.push({ name: 'overview' })"
              :name="'ticket-header-back'"
              class="is-16x16 mr-10 is-pointer"
            />
            <span class="ticket-overview-options__left__title">
              {{ localization("app-overview", "Overview") }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <top-header> </top-header>
    <div class="columns is-gapless">
      <div class="column is-8">
        <div class="ticket-priority-card">
          <div class="ticket-priority-card__header">
            <div class="ticket-priority-card__header__left">
              <sh-icon :name="'ticket-card-priority'" class="is-30x30 mr-10" />
              <span class="ticket-priority-card__header__left__title">{{
                localization("app-priority", "Priority")
              }}</span>
            </div>
            <div class="is-flex-center">
              <div
                class="is-flex-center mr-20 is-pointer"
                @click="getPriority('priority', 'status', {})"
                @mouseover="refresh = true"
                @mouseleave="refresh = false"
              >
                <sh-icon
                  :name="'ticket-history-recents'"
                  class="is-20x20 mr-5"
                />
                <div
                  class="is-medium-12-500-14 has-opacity-5 has-text-danger"
                  v-if="refresh"
                >
                  {{ localization("app-refresh", "Refresh") }}
                </div>
                <div class="is-medium-12-500-14 has-opacity-5" v-else>
                  {{ since }} mins ago
                </div>
              </div>
            </div>
          </div>
          <div class="ticket-priority-card__content has-slimscroll">
            <div
              class="ticket-priority-card__content__item"
              v-for="(priority, index) in eachCount"
              :key="index"
            >
              <div class="ticket-priority-card__content__item__right">
                <div class="columns is-flex-center is-marginless">
                  <div class="column is-3 is-paddingless">
                    <div class="priority-item-name is-medium-16-500-19">
                      <sh-icon
                        :name="`ticket-priority-${index}`"
                        class="is-16x16 mr-5"
                      />
                      <span class="is-capitalized">
                        {{
                          localization(
                            `app-${index
                              .split(" ")
                              .join("-")
                              .toLowerCase()}`,
                            index
                          )
                        }}
                      </span>
                    </div>
                  </div>
                  <div class="column is-7 is-paddingless">
                    <ticket-multiple-progress
                      :values="priority"
                      @filter_by="filter_ticket_list($event, index)"
                    ></ticket-multiple-progress>
                  </div>
                  <div
                    class="column is-2 is-paddingless member-total is-flex align-center is-center mr-5"
                  >
                    <sh-icon :name="'ticket-tag-icon'" class="is-20x20 mr-5" />

                    <span class="is-medium-14-500-17">
                      {{
                        priority.length
                          ? priority.map(e => e.count).reduce((a, b) => a + b)
                          : 0
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 ">
        <div class="ticket-priority-config-card">
          <div class="ticket-priority-config-header">
            {{ localization("app-configure", "Configure") }}
          </div>

          <div>
            <div
              class="priority-config"
              v-for="config in priority_config"
              :key="config"
            >
              <label class="is-flex is-pointer" @click="add_filter(config)">
                <input
                  type="checkbox"
                  class="sh-custom-checkbox"
                  :value="config"
                  v-model="selected_filter"
                />
                <span class="is-medium-16-500-22 ml-10">{{
                  config == "Assigned"
                    ? localization("app-assigned-to-me", "Assigned to me")
                    : config == "Raised"
                    ? localization("app-created-by-me", "Created by me")
                    : localization("app-all", "All")
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns" style="height:4rem">
      <div class="column is-5">
        <bulk-actions-bar
          :global_select="global_select"
          @refresh-tickets="getData(true)"
        ></bulk-actions-bar>
      </div>

      <div class="column is-7">
        <div class="is-pulled-right pr-20">
          <export-dropdown :allFiltersData="extra_query"></export-dropdown>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 mt-20">
        <tickets-list :extra_query="extra_query"></tickets-list>
      </div>
    </div>
  </div>
</template>

<script>
  import TicketsList from "@/app/old/desktop/tickets/components/tickets-list/tickets-list";
  import BulkActionsBar from "@/app/old/desktop/tickets/shared/components/bulk-actions";
  import ExportDropdown from "@/app/old/desktop/tickets/components/tickets-list/components/tickets-top-filters/components/export-dropdown.vue";

  import OverviewMixin from "../../../shared/mixins/overview.mixin";
  import TopHeader from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/top-header.vue";
  import { mapState } from "vuex";

  import TicketMultipleProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-multiple-progress";
  export default {
    mixins: [OverviewMixin],
    components: {
      TicketMultipleProgress,
      TicketsList,
      BulkActionsBar,
      ExportDropdown,
      TopHeader
    },
    computed: {
      ...mapState("tickets", ["filters", "selected_asset", "allFiltersData"])
    },
    data() {
      return {
        allPriority: [],
        eachCount: {
          critical: [],
          high: [],
          medium: [],
          low: []
        },
        priority_config: ["All", "Assigned", "Raised"],
        selected_filter: [],
        extra_query: {}
      };
    },
    watch: {
      filters() {
        this.init();
      },
      selected_asset() {
        this.init();
      },
      allFiltersData() {
        this.init();
      }
    },
    created() {
      this.init();
    },
    methods: {
      init() {
        this.extra_query = this.create_filter_obj();
        this.getPriority("priority", "status", this.extra_query);
        if (
          !this.extra_query ||
          (!this.extra_query.takenUp && !this.extra_query.owner)
        ) {
          this.selected_filter.push("All");
        } else {
          if (
            this.extra_query &&
            this.extra_query.takenUp == this.$store.state.claims.user_id
          ) {
            this.selected_filter.push("Assigned");
          }
          if (
            this.extra_query &&
            this.extra_query.owner == this.$store.state.claims.user_id
          ) {
            this.selected_filter.push("Raised");
          }
        }
      },
      async getPriority(xkey, ykey, filter) {
        (this.eachCount = {
          critical: [],
          high: [],
          medium: [],
          low: []
        }),
          (this.allPriority = await this.getReport(xkey, ykey, filter));
        if (this.allPriority) {
          this.allPriority.map(item => {
            return (this.eachCount[item.x] = item.y);
          });
        }

        this.eachCount = Object.assign({}, this.eachCount);
      },

      add_filter(e) {
        if (e) {
          this.selected_filter.includes(e)
            ? (this.selected_filter = _.pull(this.selected_filter, e))
            : this.selected_filter.push(e);
        }
        this.extra_query.takenUp = _.pull(
          this.extra_query.takenUp,
          this.$store.state.claims.user_id
        );
        this.extra_query.owner = _.pull(
          this.extra_query.owner,
          this.$store.state.claims.user_id
        );

        if (e == "All") {
          _.pullAll(this.selected_filter, ["Assigned", "Raised"]);
          this.extra_query.takenUp = _.pull(
            this.extra_query.takenUp,
            this.$store.state.claims.user_id
          );
          this.extra_query.owner = _.pull(
            this.extra_query.owner,
            this.$store.state.claims.user_id
          );
        } else {
          this.$store.dispatch("tickets/add_tickets", []);
          if (
            this.selected_filter.includes("Assigned") &&
            !this.selected_filter.includes("Raised")
          ) {
            this.extra_query.owner = _.pull(
              this.extra_query.owner,
              this.$store.state.claims.user_id
            );
            this.extra_query["takenUp"].push(this.$store.state.claims.user_id);

            this.selected_filter = _.pull(this.selected_filter, "All");
          }
          if (
            this.selected_filter.includes("Raised") &&
            !this.selected_filter.includes("Assigned")
          ) {
            this.extra_query.takenUp = _.pull(
              this.extra_query.takenUp,
              this.$store.state.claims.user_id
            );
            this.extra_query["owner"].push(this.$store.state.claims.user_id);

            this.selected_filter = _.pull(this.selected_filter, "All");
          }
          if (
            this.selected_filter.includes("Raised") &&
            this.selected_filter.includes("Assigned")
          ) {
            this.extra_query["owner"].push(this.$store.state.claims.user_id);
            this.extra_query["takenUp"].push(this.$store.state.claims.user_id);

            this.selected_filter = _.pull(this.selected_filter, "All");
          }
          if (!this.selected_filter || !this.selected_filter.length) {
            this.selected_filter = ["All"];
          }
        }

        this.getPriority("priority", "status", this.extra_query);
      },
      filter_ticket_list(status, priority) {
        this.$store.dispatch("tickets/add_tickets", []);
        let new_query = { status: status, priority: priority, offset: 0 };
        this.extra_query = { ...this.extra_query, ...new_query };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ticket-priority-expand {
    padding: 1rem;
  }

  .ticket-priority-config {
    &-card {
      background-color: $white;
      border-radius: 1rem;
      box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
      margin: 2rem 1rem 0 1rem;
    }
    &-header {
      border-bottom: 1px solid #f0f1f3;
      padding: 1.5rem 3rem;
      font-family: Barlow-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: $ticket-heading;
    }
  }

  .ticket-priority-card {
    background-color: $white;
    border-radius: 1rem;
    box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
    margin: 2rem 1rem 0 1rem;
    padding: 1.5rem 1.5rem 3rem 1.5rem;

    &__header {
      display: flex;
      justify-content: space-between;

      &__left {
        display: flex;
        align-items: center;
        &__title {
          font-family: Barlow-Medium !important;
          font-size: 18px !important;
          font-weight: 500 !important;
          line-height: 22px !important;
          color: $ticket-heading;
        }
      }
    }

    &__content {
      max-height: 380px;
      overflow-y: auto;
      &__item {
        display: flex;
        align-items: center;
        padding: 2rem 0 2rem 2.5rem;

        img {
          border-radius: 50%;
        }
        .priority-item-name {
          display: flex;
          align-items: center;
          color: $secondary-text;
          // padding-bottom: 0.5rem;
        }
        .item-desc {
          color: $primary-text;
          opacity: 0.7;
          // min-width: 15rem;
          // max-width: 15rem;
        }

        &__right {
          position: relative;
          width: 100%;
        }
      }
    }
  }
  .member-total {
    text-align: center;
  }
  .priority-config:first-child {
    padding: 2rem 3rem 1rem 3rem;
  }
  .priority-config {
    padding: 1rem 3rem 1rem 3rem;
  }
  .priority-config:last-child {
    padding: 1rem 3rem 2rem 3rem;
  }
</style>
