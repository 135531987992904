<template>
  <div>
    <div class="columns is-flex is-vcenter px-20" style="height:8rem">
      <div class="column is-5">
        <span class="is-flex align-center ">
          <sh-icon
            @click.native="$router.push({ name: 'overview' })"
            :name="'ticket-header-back'"
            class="is-16x16 mr-10 is-pointer"
          />
          <span class="ticket-overview-options__left__title" v-if="name">
            {{ localization("app-showing", "Showing") }}
            {{ localization(`app-${name.toLowerCase()}`, name) }}</span
          >
        </span>
      </div>
      <div class="column is-6">
        <bulk-actions-bar :extra_filters="filter_query"></bulk-actions-bar>
      </div>
      <div class="column is-1">
        <export-dropdown :allFiltersData="filter_query"></export-dropdown>
      </div>
    </div>

    <div class="columns">
      <div class="column is-12 mt-20">
        <tickets-list :extra_query="filter_query"></tickets-list>
      </div>
    </div>
  </div>
</template>
<script>
  import TicketsList from "@/app/old/desktop/tickets/components/tickets-list/tickets-list";
  import BulkActionsBar from "@/app/old/desktop/tickets/shared/components/bulk-actions";
  import ExportDropdown from "@/app/old/desktop/tickets/components/tickets-list/components/tickets-top-filters/components/export-dropdown.vue";

  export default {
    props: ["filters"],
    data() {
      return {
        filter_query: {},
        name: null
      };
    },
    components: {
      TicketsList,
      BulkActionsBar,
      ExportDropdown
    },
    created() {
      if (this.filters) {
        this.$store.commit("tickets/SET_SORT_KEY", this.filters.sort);

        let query = _.omit(this.filters, [
          "name",
          "pageSize",
          "pageNumber",
          "sort"
        ]);

        this.filter_query = query;
      } else {
        this.$route.push({ name: "overview" });
      }
    },
    mounted() {
      this.name = this.filters.name;
    }
  };
</script>
