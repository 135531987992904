<template>
  <div>
    <ticket-list></ticket-list>
  </div>
</template>

<script>
  import TicketList from "@/app/old/desktop/tickets/components/tickets-list/tickets-list";
  export default {
    components: {
      TicketList
    }
  };
</script>

<style lang="scss" scoped></style>
