<template>
  <div>
    <div class="columns list-card is-flex align-center" v-if="item">
      <div
        class="column"
        :class="[$route.name.includes('asset-details') ? 'is-3' : 'is-4']"
      >
        <router-link
          :to="{ name: 'therm-viewer', params: { id: item.uid } }"
          @click.native="append_recently_accessed_resource('therm', item.uid)"
        >
          <main-details-media
            :truncate_value="36"
            :hideImage="true"
            :show_tooltip="true"
            :info="{
              name: item.name,
              name_class:
                'is-semiBold-16-600-19 has-text-theme-black card-list-title',
              subtext: localization('app-thermal-scanning', 'Thermal Scanning'),
              subtext_class:
                ' is-regular-14-500-17 has-text-theme-grey has-opacity-7',
              type: 'projectManagement'
            }"
          ></main-details-media>
          <!-- <info-block
            :hideImage="true"
            :info="{
              name: item.name,
              name_class: 'is-semiBold-16-600-19 has-text-theme-black card-list-title',
              bottom: localization('app-thermal-scanning', 'Thermal Scanning'),
              bottom_class: ' is-regular-14-500-17 has-text-theme-grey has-opacity-7',
              type: 'projectManagement',
            }"
            :truncate_value="50"
          /> -->
        </router-link>
      </div>
      <div
        class="column "
        :class="[$route.name.includes('asset-details') ? 'is-3' : 'is-2_5']"
        v-if="$route.name != 'dashboard'"
      >
        <div class="is-flex is-vcenter">
          <user-avatar
            :user="item.users.owner"
            :pxsize="30"
            :custom_class="'tiny-user-avatar'"
            :show_tooltip="true"
          ></user-avatar>
          <p
            class="ml-5 is-medium-14-500-17 has-text-weight-semibold has-text-lightgrey is-capitalized"
            v-if="item.users && item.users.owner"
            v-username:[15].truncate="item.users.owner"
          ></p>
        </div>
      </div>
      <!-- <div class="column is-4">
        <div class="columns"> -->
      <div
        class="column is-flex is-center"
        :class="[$route.name.includes('asset-details') ? 'is-1_5' : 'is-1']"
      >
        <h1 class="is-medium-16-500-19 has-text-theme-black mt-5 affected">
          {{
            (
              (item.metrics.modules.affected * (item.moduleWattPeak || 300)) /
              1000
            ).toFixed(1)
          }}KW
        </h1>
      </div>
      <div
        class="column is-flex is-center"
        :class="[$route.name.includes('asset-details') ? 'is-1_5' : 'is-1_5']"
      >
        <h1 class="is-medium-16-500-19 has-text-theme-black mt-5 capacity">
          {{
            (
              item.metrics.modules.total *
              ((item.moduleWattPeak || 300) / 1000000)
            ).toFixed(1)
          }}MW
        </h1>
      </div>
      <div class="column is-1 is-flex is-center">
        <h1 class="is-medium-16-500-19 has-text-theme-black mt-5">
          {{
            (item.metrics.issues && total_issues(item.metrics.issues)) || "0"
          }}
        </h1>
      </div>
      <!-- </div>
      </div> -->
      <div class="column is-2  is-flex is-end">
        <router-link
          :to="{ name: 'therm-view-details', params: { id: item.uid } }"
          class="is-pointer button  is-flex is-vcenter ml-30 blue-background"
        >
          <span class="is-medium-12-500-14 mr-5">
            {{ localization("app-report-dashboard", "Report Dashboard") }}
          </span>
          <i class="fas fa-chevron-right mt-1"></i>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
  import InfoBlock from "@/app/old/desktop/shared/components/info-block";
  import MainDetailsMedia from "@/app/old/desktop/shared/components/main-details-media";

  export default {
    props: ["item"],
    components: {
      InfoBlock,
      MainDetailsMedia
    },
    methods: {
      total_issues(issues) {
        return _.sum(Object.values({ ...issues, table: 0 }));
      }
    }
  };
</script>

<style lang="scss">
  .list-card {
    &:hover {
      .card-list-title {
        color: $primary !important;
      }
      .affected {
        color: $therm-affected !important;
      }
      .capacity {
        color: $therm-capacity !important;
      }
    }
  }
  .blue-background {
    background: #2585d3;
    color: #fff;
  }
</style>
