var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-sidebar"},[_c('div',{staticClass:"ticket-sidebar-title"},[_c('img',{attrs:{"src":require("@/assets/icons/tickets/ticket-logo.svg"),"alt":""}}),_c('div',{staticClass:"ticket-sidebar-title__right"},[_c('div',{staticClass:"primary-title is-semiBold-16-600-19"},[_vm._v(" "+_vm._s(_vm.localization("app-tickets", "Tickets"))+" ")]),_c('div',{staticClass:"secondary-title is-regular-12-00-14"},[_vm._v(" "+_vm._s(_vm.localization( "app-manage-assets-with-ease", "Manage assets with ease!" ))+" ")])])]),_c('div',{staticClass:"ticket-sidebar-content"},_vm._l((_vm.ticketNavList),function(nav){return _c('router-link',{key:nav.name,staticClass:"ticket-sidebar-content__item",class:{
        'is-active':
          _vm.$route.name == nav.name ||
          (_vm.$route.path.includes(nav.name) &&
            _vm.$route.name !== 'filtered-tickets-list')
      },attrs:{"to":{ name: nav.to, query: { asset: _vm.$route.query.asset } }},nativeOn:{"click":function($event){return _vm.clear_state($event)}}},[(_vm.$route.name == nav.name)?_c('sh-icon',{staticClass:"is-24x24 ticket-sidebar-content__item__icon",attrs:{"name":((nav.icon) + "-active")}}):_c('sh-icon',{staticClass:"is-24x24 ticket-sidebar-content__item__icon",attrs:{"name":("" + (nav.icon))}}),_c('span',{staticClass:"is-medium-14-500-00 item-name is-capitalized"},[_vm._v(_vm._s(_vm.localization(("app-" + (nav.name)), nav.name)))]),_c('div',{class:{
          'active-indicator':
            _vm.$route.name == nav.name ||
            (_vm.$route.path.includes(nav.name) &&
              _vm.$route.name !== 'filtered-tickets-list')
        }})],1)}),1)])}
var staticRenderFns = []

export { render, staticRenderFns }