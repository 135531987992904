<template>
  <div
    class="columns is-marginless bb-1 has-text-centered has-background-white is-normal-14-500-17 has-text-theme-grey py-10 px-15"
  >
    <div class="column is-narrow"></div>
    <div
      @click="update_sort_key('name')"
      class=" column is-4 is-flex is-vcenter"
    >
      <span class="is-pointer ml-30">
        {{ localization("app-checklists", "Checklists") }}
      </span>
      <span class="sort-indicators">
        <i
          class="fas fa-chevron-up fa-xs header-sort-icon"
          :class="[
            sorting_key === 'name' ? 'has-text-blue' : 'has-text-theme-grey'
          ]"
        ></i>
        <i
          class="fas fa-chevron-down fa-xs header-sort-icon"
          :class="[
            sorting_key === '-name' ? 'has-text-blue' : 'has-text-theme-grey'
          ]"
        ></i>
      </span>
    </div>
    <div class="column is-4 is-flex is-vcenter">
      <span class="is-pointer pl-15">
        {{ localization("app-created-by", "Created by") }}
      </span>
    </div>
    <div
      @click="update_sort_key('createdAt')"
      class="column is-flex is-vcenter is-2"
    >
      <span class="is-pointer">{{
        localization("app-created", "Created")
      }}</span>
      <span class="sort-indicators">
        <i
          class="fas fa-chevron-up fa-xs header-sort-icon"
          :class="[
            sorting_key == 'createdAt' ? 'has-text-blue' : 'has-text-theme-grey'
          ]"
        ></i>
        <i
          class="fas fa-chevron-down fa-xs header-sort-icon"
          :class="[
            sorting_key == '-createdAt'
              ? 'has-text-blue'
              : 'has-text-theme-grey'
          ]"
        ></i>
      </span>
    </div>
  </div>
</template>
<script>
  export default {
    props: ["sorting_key"],
    methods: {
      update_sort_key(value) {
        let new_value;
        this.sorting_key == value
          ? (new_value = "-" + value)
          : (new_value = value);
        this.$emit("update_sort_key", new_value);
      }
    }
  };
</script>
<style lang="scss">
  .header-sort-icon {
    cursor: pointer;
    height: 4px;
    width: 12px;
    margin: -2px 3px;
    display: table;
    font-weight: 600;
    font-size: 8px;
  }
</style>
