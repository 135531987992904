<template>
  <div>
    <div class="ticket-overview-options">
      <div class="ticket-overview-options__left">
        <span class="is-flex align-center">
          <span class="ticket-overview-options__left__title">
            {{ localization("app-overview", "Overview") }}
          </span>
        </span>
        <asset-dropdown class="px-10"></asset-dropdown>
        <display-dropdown></display-dropdown>
        <span @click="open_filter = true" class="assets-selection">
          <span>
            <span class="is-medium-14-500-17 option-title"
              >{{ localization("app-filters", "Filters") }}:
            </span>
            <span
              class="option-value is-semiBold-14-600-17"
              :class="{ 'has-text-blue': is_filters_selected }"
              >{{
                is_filters_selected
                  ? localization("app-selected", "Selected")
                  : localization("app-all", "All")
              }}</span
            >
          </span>

          <sh-icon
            :name="open_filter ? 'expand' : 'collapse'"
            class="is-16x16 is-pointer"
          ></sh-icon>
          <filter-dropdown
            v-if="open_filter"
            v-click-outside="() => (open_filter = false)"
            :filtersData="allFiltersData"
            @apply="open_filter = false"
            @close="open_filter = false"
          />
        </span>
      </div>
      <div class="ticket-overview-options__right is-medium-14-500-17">
        <slot name="right-side"></slot>
      </div>
    </div>
    <div class="columns" v-if="openFilterDropdown">
      <div class="column is-12">
        <filter-card :ticket="all_tickets"></filter-card>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import AssetDropdown from "@/app/old/desktop/tickets/components/tickets-list/components/tickets-top-filters/components/asset-dropdown.vue";
  import FilterDropdown from "@/app/old/desktop/tickets/shared/components/filter-card/filter-dropdown";
  import DisplayDropdown from "@/app/old/desktop/tickets/components/tickets-list/components/tickets-top-filters/components/display-dropdown.vue";
  export default {
    props: ["assets"],
    components: {
      AssetDropdown,
      FilterDropdown,
      DisplayDropdown
    },

    data() {
      return {
        openFilterDropdown: false,
        openDisplayDropdown: false,
        open_filter: false,
        selected_display_option: "All"
      };
    },

    computed: {
      ...mapState("tickets", [
        "filters",
        "selected_asset",
        "allFiltersData",
        "is_filters_selected"
      ])
    },
    created() {
      if (!this.filters || !this.filters.length) {
        this.reset_filters();
      }
    },
    methods: {
      activate_filter() {
        this.openFilterDropdown = !this.openFilterDropdown;
      },
      reset_filters() {
        let filters = [
          { label: "Archived", key: "archived", value: true, active: false },
          {
            label: "Favorites",
            key: "bookmarked",
            value: true,
            active: this.$route.path.includes("/ticket-dashboard/favorites")
              ? true
              : false
          },
          { label: "Overdue", key: "overdue", value: true, active: false },
          {
            label: "Assigned to me",
            key: "takenUp",
            value: this.$store.state.claims
              ? this.$store.state.claims.user_id
              : null,
            active: false
          },
          {
            label: "Created by me",
            key: "owner",
            value: this.$store.state.claims
              ? this.$store.state.claims.user_id
              : null,
            active: false
          }
        ];
        this.$store.commit("tickets/SET_FILTERS", filters);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .add-new-checklist {
    cursor: pointer;
    height: 3.6rem;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    border-radius: 4px;
    background-color: $white;
    color: $primary;
    margin-left: 2rem;
  }
</style>
