<template>
  <div class="ticket-card">
    <div class="ticket-card-options">
      <div
        class="ticket-card-options__left is-pointer"
        @click="getMembers('assignee', 'status', {})"
        @mouseover="refresh = true"
        @mouseleave="refresh = false"
      >
        <sh-icon :name="'ticket-history-recents'" class="is-24x24 mr-5" />
        <div
          class="is-medium-12-500-14 has-opacity-5 has-text-danger"
          v-if="refresh"
        >
          {{ localization("app-refresh", "Refresh") }}
        </div>
        <div class="is-medium-12-500-14 has-opacity-5" v-else>
          {{ since }} mins ago
        </div>
      </div>
      <div class="ticket-card-options__right">
        <sh-icon
          :name="'ticket-download'"
          class="is-24x24 mr-15 is-pointer is-hidden"
        />
        <span
          @click="member_expand()"
          :class="[
            !eachCount || !Object.keys(eachCount).length
              ? 'has-no-access'
              : null
          ]"
        >
          <sh-icon
            :name="'ticket-expand-maximize'"
            class="is-24x24 is-pointer"
          />
        </span>
      </div>
    </div>

    <div class="ticket-card-heading">
      <div class="ticket-card-heading__icon">
        <sh-icon :name="'ticket-card-members'" class="is-50x50" />
      </div>
      <div class="ticket-card-heading__title ">
        {{ localization("app-assignee", "Assignee") }}
      </div>
      <div class="ticket-card-heading__subtitle">
        <div class="member-state">
          <div class="member-status member-status__active"></div>
        </div>
        <div class="ticket-dropdown">
          <span class="option-value is-medium-14-500-17">{{
            localization("app-all", "All")
          }}</span>
          <ticket-dropdown
            @filter_selection="add_filter($event)"
            v-if="open_ticket_dropdown"
            v-click-outside="() => (open_ticket_dropdown = false)"
            :dropdown_items="dropdown_items"
          ></ticket-dropdown>
        </div>
      </div>
    </div>
    <div
      class="ticket-card-member"
      v-if="eachCount && Object.keys(eachCount).length"
    >
      <div
        class="ticket-card-member__list"
        v-for="(stats, index) in sortedMembers"
        :key="index"
      >
        <template v-if="index < 7">
          <div
            class="mr-10 avatar-img"
            v-if="$store.getters.state_users[stats]"
          >
            <user-avatar
              :custom_class="'tiny-user-avatar'"
              :user="$store.getters.state_users[stats]"
              class="is-pointer"
              :show_tooltip="true"
              :pxsize="30"
            ></user-avatar>
          </div>

          <div
            class="ticket-card-member__list__details"
            v-if="$store.getters.state_users[stats]"
          >
            <div
              class="ticket-card-member__list__details__top is-medium-16-500-19"
            >
              <div class="ticket-db-list__details__left">
                <span
                  class=""
                  v-username="$store.getters.state_users[stats]"
                ></span>
              </div>
              <div class="ticket-db-list__details__right">
                {{
                  (eachCount[stats].closed && eachCount[stats].closed.count) ||
                    0
                }}/{{ eachCount[stats].total }}
              </div>
            </div>
            <div class="ticket-card-member__list__details__bottom">
              <ticket-progress
                :total="eachCount[stats].total"
                :value="
                  (eachCount[stats].closed && eachCount[stats].closed.count) ||
                    0
                "
                :valueClass="'member-progress-value'"
              ></ticket-progress>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div v-else>
      <p class="is-regular-14-500-17 has-text-centered has-opacity-8 py-20">
        {{
          localization(
            "app-no-tickets-found-with-the-selected-filters",
            "No tickets found with the selected filters!"
          )
        }}
      </p>
    </div>

    <div class="ticket-card-showmore is-medium-14-500-17">
      <span
        v-if="showmore"
        class="is-pointer is-flex align-center"
        @click="member_expand()"
        :class="[
          !eachCount || !Object.keys(eachCount).length
            ? 'has-no-access has-text-grey'
            : null
        ]"
      >
        <span>{{ localization("app-show-more", "Show more") }}</span>
        <sh-icon
          v-if="!eachCount || !Object.keys(eachCount).length"
          :name="'right-arrow-grey'"
          class="is-12x12 mt-2 ml-5"
        ></sh-icon>
        <img
          class="ml-5 mt-2"
          v-else
          src="@/assets/icons/tickets/showmore-arrow.png"
          alt=""
        />
      </span>
    </div>
  </div>
</template>

<script>
  import TicketDropdown from "../../../shared/filters/ticket-dropdown";
  import OverviewMixin from "../../../shared/mixins/overview.mixin";
  import TicketProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-progress.vue";

  export default {
    mixins: [OverviewMixin],
    props: ["top_header_query"],
    components: {
      TicketDropdown,
      TicketProgress
    },
    watch: {
      top_header_query() {
        this.getMembers("assignee", "status", {});
      }
    },
    data() {
      return {
        open_ticket_dropdown: false,
        eachCount: {},
        allMembers: null,
        dropdown_items: [
          { id: "1", name: "All" },
          { id: "2", name: "Active" }
        ],

        ticketMember: [],
        showmore: true,
        sortedMembers: null
      };
    },
    created() {
      this.getMembers("assignee", "status", {});
    },

    methods: {
      async getMembers(xkey, ykey, filter) {
        this.eachCount = {};
        this.allMembers = await this.getReport(xkey, ykey, filter);
        if (this.allMembers) {
          this.allMembers.map(item => {
            if (item.x && this.$store.getters.state_users[item.x]) {
              return (this.eachCount[item.x] = {
                total: _.sumBy(item.y, function(o) {
                  return o.count;
                }),
                closed: _.remove(item.y, function(y) {
                  return y.type == "closed";
                })[0]
              });
            }
          });
        }

        this.eachCount = Object.assign({}, this.eachCount);
        let eachMemberTotal = _.mapValues(this.eachCount, function(o) {
          return o.total;
        });
        let sortedMembers = Object.entries(eachMemberTotal).sort(
          (a, b) => b[1] - a[1]
        );
        this.sortedMembers = sortedMembers.map(o => o[0]);
      },
      add_filter(e) {
        let filter;
        switch (e) {
          case "Active":
            filter = { Active: true };
            break;
          default:
            filter = {};
        }
        this.getMembers("assignee", "status", filter);
      },

      member_expand() {
        this.$router.push({
          name: "overview-member",
          params: { filters: this.top_header_query }
        });
      }
    }
  };
</script>

<style lang="scss">
  .member-state {
    height: 1rem;
    width: 1rem;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    .member-status {
      width: 6px;
      height: 6px;
      background-color: #00dda4;
      border-radius: 50%;
    }
  }
</style>
