var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"columns is-marginless bb-1 has-text-centered has-background-white is-normal-14-500-17 has-text-theme-grey py-10 px-15"},[_c('div',{staticClass:"column is-narrow"}),_c('div',{staticClass:" column is-4 is-flex is-vcenter",on:{"click":function($event){return _vm.update_sort_key('name')}}},[_c('span',{staticClass:"is-pointer ml-25"},[_vm._v(" "+_vm._s(_vm.localization("app-templates", "Templates"))+" ")]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
          _vm.sorting_key === 'name' ? 'has-text-blue' : 'has-text-theme-grey'
        ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
          _vm.sorting_key === '-name' ? 'has-text-blue' : 'has-text-theme-grey'
        ]})])]),_c('div',{staticClass:"column is-2 is-flex is-vcenter"},[_c('span',{staticClass:"is-pointer"},[_vm._v(" "+_vm._s(_vm.localization("app-created-by", "Created by"))+" ")])]),_c('div',{staticClass:"column is-flex is-vcenter is-2",on:{"click":function($event){return _vm.update_sort_key('createdAt')}}},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-created", "Created")))]),_c('span',{staticClass:"sort-indicators"},[_c('i',{staticClass:"fas fa-chevron-up fa-xs header-sort-icon",class:[
          _vm.sorting_key == 'createdAt' ? 'has-text-blue' : 'has-text-theme-grey'
        ]}),_c('i',{staticClass:"fas fa-chevron-down fa-xs header-sort-icon",class:[
          _vm.sorting_key == '-createdAt'
            ? 'has-text-blue'
            : 'has-text-theme-grey'
        ]})])]),_c('div',{staticClass:"column is-flex is-vcenter is-3"},[_c('span',{staticClass:"is-pointer"},[_vm._v(_vm._s(_vm.localization("app-tags", "Tags")))])])])}
var staticRenderFns = []

export { render, staticRenderFns }