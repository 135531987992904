<template>
  <div
    class="checklist-item bb-1 is-pointer"
    v-if="checklist"
    @mouseenter="hover = true"
    @mouseleave="hover = false"
  >
    <div class="columns has-full-width">
      <div class="column is-narrow">
        <div
          class="is-pointer"
          v-tooltip="{
            content: localization('app-bookmark', 'Bookmark'),
            html: false
          }"
          @click.stop="update_checklist({ bookmark: !checklist.bookmark })"
          :class="{ 'is-vhidden': !hover && !checklist.bookmark }"
        >
          <sh-icon
            :name="checklist.bookmark ? 'bookmarked-highlight' : 'bookmark'"
            class="is-18x18 mr-10 mt-3"
          />
        </div>
      </div>
      <div class="column is-4" @click="view_details(checklist)">
        <div class="is-medium-16-500-19 mr-10 is-pointer">
          {{ checklist.name }}
        </div>
        <div class="is-flex align-center pt-5">
          <div class="is-flex align-center mr-15">
            <sh-icon
              :name="'ticket-checklists-attachments'"
              class="has-opacity-8 is-16x16 is-pointer mr-7"
            />
            <div class="mr-10 has-opacity-6">
              {{ checklist.attachmentsCount ? checklist.attachmentsCount : 0 }}
            </div>
          </div>
          <div class="is-medium-14-500-17 has-opacity-2">|</div>
          <div class="is-flex align-center ml-15">
            <sh-icon
              :name="'checkbox'"
              class="has-opacity-8 is-16x16 is-pointer mr-7"
            />
            <div class="mr-10 has-opacity-7 is-medium-14-500-17">
              {{ checklist.checkedItems ? checklist.checkedItems : 0 }}
              <span>/</span>
              {{ checklist.totalItems ? checklist.totalItems : 0 }}
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 is-flex align-center">
        <user-avatar
          :user="checklist.owner"
          :pxsize="24"
          :custom_class="'tiny-user-avatar'"
        ></user-avatar>
        <p
          class="is-medium-14-500-17 ml-8 truncate-text is-width-15"
          v-username="checklist.owner"
        ></p>
      </div>
      <div
        class="column is-3 is-medium-14-500-17 is-flex align-center no-whitespace-wrap"
      >
        {{ checklist.createdAt | prettyDate }}
      </div>
      <!-- <div class="column is-3 is-flex align-center">
        <assigned-tags
          v-if="checklist.tags && checklist.tags.length"
          :number_of_tags_to_display="3"
          :resourceId="checklist.uid"
          :tags="checklist.tags"
          :write_access="false"
        />
        <div v-else class="is-flex align-center">
          <sh-icon :name="'tags'" class="is-20x20 mr-5 has-opacity-6"></sh-icon>
          <span class="is-regular-14-00-17 has-opacity-6">{{localization('app-no-tags-assigned', '*No tags assigned')}}</span>
        </div>
      </div> -->
      <div
        class="column is-1"
        v-if="hover"
        @click.stop="
          showDeleteModal = true;
          $modal.show(`delete-checklist-${checklist.uid}`);
        "
      >
        <sh-icon
          :name="'trash'"
          class="is-20x20 mr-5 mt-10 is-pointer has-opacity-6"
        ></sh-icon>
      </div>
    </div>

    <prompt-modal
      :name="`delete-checklist-${checklist.uid}`"
      v-if="showDeleteModal"
      :heading="checklist.name"
      :iconPath="require('@/assets/icons/prompt/delete.svg')"
      :footer="true"
      :cancelString="'Cancel'"
      :confirmString="'Delete'"
      @cancel="$modal.hide(`delete-checklist-${checklist.uid}`)"
      @confirm="delete_template()"
      @closed="$modal.hide(`delete-checklist-${checklist.uid}`)"
    >
      <div class="prompt-modal__content__text" slot="content">
        {{
          localization(
            "app-are-you-sure-you-want-to-delete-the-checklist?",
            "Are you sure you want to delete the Checklist?"
          )
        }}
      </div>
    </prompt-modal>
  </div>
</template>

<script>
  import UserTag from "@/app/old/desktop/shared/components/tags/user-tag-new";
  import ChecklistMixin from "@/app/old/desktop/tickets/components/ticket-dashboard/components/checklists/components/checklist-mixin";
  import TicketProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-progress.vue";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import PromptModal from "@/app/old/desktop/shared/components/prompt-modal";

  export default {
    props: ["checklist", "index"],
    data() {
      return {
        service: ticketService,
        hover: false,
        assignees: [],
        showDeleteModal: false
      };
    },
    mixins: [ChecklistMixin],
    components: {
      UserTag,
      TicketProgress,
      PromptModal
    },
    created() {
      this.get_assignees(this.checklist);
    },
    methods: {
      view_details() {
        this.$router.push({
          params: { id: this.checklist.uid },
          name: "checklist-details"
        });
      },
      delete_template() {
        console.log("deleting"); //  dispatch
        this.$store.dispatch("checklist/delete_template_checklist", {
          checklist_id: this.checklist.uid
        });

        this.$modal.hide(`delete-checklist-${this.checklist.uid}`);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .check-list-desc {
    font-family: Barlow-Medium !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .checklist-item {
    padding: 1.5rem 6rem 1.5rem 3rem;
    background-color: $white;
    &:hover {
      background-color: $hover-new;
    }
  }
</style>
