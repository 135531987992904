var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-list-item",on:{"mouseenter":function($event){_vm.hover = true},"mouseleave":function($event){_vm.dropdown ? null : (_vm.hover = false)}}},[_c('div',{staticClass:"ticket-list"},[_c('div',{staticClass:"columns "},[_c('div',{staticClass:"column is-4 is-flex"},[_c('div',{staticClass:"is-pointer ml-25 mr-10",class:{ 'is-vhidden': !_vm.hover && !_vm.ticket.bookmark },on:{"click":function($event){return _vm.toggle_bookmark()}}},[_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":_vm.ticket.bookmark ? 'bookmarked-highlight' : 'bookmark'}})],1),_c('div',[_c('div',{staticClass:"is-medium-16-500-19 no-whitespace-wrap is-flex is-vcenter is-relative"},[_c('p',{staticClass:"is-pointer has-text-dark mb-5"},[(!_vm.edit_ticket_name)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(
                  _vm.ticket.name && _vm.ticket.name.length > 30 ? _vm.ticket.name : null
                ),expression:"\n                  ticket.name && ticket.name.length > 30 ? ticket.name : null\n                "}],staticClass:"ticket-name-text",on:{"click":function($event){return _vm.$router.push({
                    name: 'schedule-details',
                    params: { id: _vm.ticket.schedule.uid, ticket: _vm.ticket.uid }
                  })}}},[_vm._v(_vm._s(_vm._f("truncate")(_vm.ticket.name,30)))]):(_vm.edit_ticket_name)?_c('input',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.edit_ticket_name = false); }),expression:"() => (edit_ticket_name = false)"},{name:"focus",rawName:"v-focus"},{name:"model",rawName:"v-model",value:(_vm.ticket.name),expression:"ticket.name"}],staticClass:"input is-inline-input is-medium-16-500-19 ticket-input",attrs:{"type":"text","name":"name","id":"ticket","placeholder":_vm.ticket.name},domProps:{"value":(_vm.ticket.name)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }_vm.edit_ticket_name = -1},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.update_ticket_name($event)}],"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.ticket, "name", $event.target.value)}}}):_vm._e()])]),_c('span',{staticClass:"is-medium-14-500-17 has-opacity-7"},[_vm._v(" "+_vm._s(_vm.localization("app-next-occurence", "Next Occurence"))+" ")]),_c('span',{staticClass:"is-semiBold-14-600-17"},[_vm._v(" "+_vm._s(_vm.next_occurrence ? _vm.next_occurrence == "-" ? _vm.next_occurrence : _vm.next_occurrence == "0" ? _vm.localization("app-today", "Today") : _vm.next_occurrence == "1" ? _vm.localization("app-today", "Tomorrow") : ((_vm.localization( "app-in", "in" )) + " " + _vm.next_occurrence + " " + (_vm.localization("app-days", "days"))) : _vm.localization("app-not-available", "*Not available"))+" ")])]),_c('div',{staticClass:"is-relative",class:[_vm.edit_ticket_name ? '' : 'left-10']},[_c('sh-icon',{staticClass:" is-16x16 is-inline is-pointer",class:{ 'is-hidden': !_vm.hover || !_vm.ticket.writeAccess },attrs:{"name":[_vm.edit_ticket_name ? 'tick-set-complete' : 'pen']},nativeOn:{"click":function($event){_vm.edit_ticket_name
                ? _vm.update_ticket_name()
                : (_vm.edit_ticket_name = true)}}})],1)]),_c('div',{staticClass:"column is-2 is-flex align-center is-between"},[_c('p',{staticClass:"is-flex is-vcenter"},[_c('user-avatar',{staticClass:"mr-7",attrs:{"user":_vm.ticket.owner,"pxsize":30,"custom_class":'tiny-user-avatar',"show_tooltip":true}}),_c('span',{directives:[{name:"username",rawName:"v-username",value:(_vm.ticket.owner),expression:"ticket.owner"}],staticClass:" username-truncate is-medium-14-500-17 has-text-new-grey"})],1)]),_c('div',{staticClass:"column is-2 is-flex align-center is-between is-medium-14-500-17 has-text-new-grey"},[_vm._v(" "+_vm._s(new Date(_vm.ticket.createdAt).toLocaleString(_vm.locale, { month: "short", day: "numeric", year: "numeric", hour: "numeric", minute: "2-digit" }))+" ")]),_c('div',{staticClass:"column is-3 is-vcenter is-flex"},[_c('tags-dropdown',{attrs:{"service":_vm.ticketService,"tags":_vm.ticket.tags,"resourceId":_vm.ticket.uid,"number_of_tags_to_display":3,"write_access":_vm.ticket.writeAccess,"display_on_hover":!_vm.hover,"complete":_vm.update_ticket}})],1),_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.dropdown ? (_vm.dropdown = false) : null); }),expression:"() => (dropdown ? (dropdown = false) : null)"}],staticClass:"column is-narrow is-pointer ",class:{ 'is-hidden': !_vm.hover }},[_c('div',{staticClass:"mt-15"},[_c('sh-icon',{staticClass:"is-20x20 mr-10",attrs:{"name":'3-dots-menu'},nativeOn:{"click":function($event){_vm.dropdown = true}}})],1),_c('div',[_c('div',{staticClass:"asset-dropdown "},[(_vm.dropdown)?_c('div',{staticClass:"asset-dropdown-content"},[_c('p',{on:{"click":function($event){_vm.edit_ticket_name = true}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'edit-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-edit", "Edit"))+" ")])],1),_c('p',{on:{"click":function($event){_vm.showDeleteModal = true;
                  _vm.$modal.show(("delete-schedule-" + (_vm.ticket.uid)));}}},[_c('sh-icon',{staticClass:"is-24x24 mr-10",attrs:{"name":'delete-small'}}),_c('span',{staticClass:"no-whitespace-wrap"},[_vm._v(_vm._s(_vm.localization("app-delete", "Delete")))])],1)]):_vm._e()])])])])]),(_vm.showDeleteModal)?_c('prompt-modal',{attrs:{"name":("delete-modal-" + (_vm.ticket.uid)),"heading":_vm.ticket.name,"iconPath":require('@/assets/icons/prompt/delete.svg'),"footer":true,"cancelString":'Cancel',"confirmString":'Delete'},on:{"cancel":function($event){return _vm.$modal.hide(("delete-modal-" + (_vm.ticket.uid)))},"confirm":_vm.delete_schedule,"closed":function($event){return _vm.$modal.hide(("delete-modal-" + (_vm.ticket.uid)))}}},[_c('div',{staticClass:"prompt-modal__content__text",attrs:{"slot":"content"},slot:"content"},[_vm._v(" "+_vm._s(_vm.localization( "app-delete-schedule-confirmation", "Are you sure you want to delete the Schedule?" ))+" ")])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }