<template>
  <div class="ticket-checklists">
    <div class="tickets-top-section">
      <div class="tickets-top-filters">
        <div class="left">
          <div class="is-flex align-center header mr-10">
            <sh-icon
              v-if="$route.name == 'template-details'"
              :name="'ticket-header-back'"
              @click.native="$router.push({ name: 'templates' })"
              class="is-16x16 mr-10 is-pointer"
            />
            <h1
              class="left-heading"
              :class="{ 'mt-5': $route.name != 'template-details' }"
            >
              {{ localization(`app-templates`, "Templates") }}
            </h1>
          </div>
          <sort-dropdown
            class="mt-8"
            @sort="
              sort_by = $event;
              getData();
            "
          ></sort-dropdown>
        </div>
      </div>
      <div class="columns is-flex is-vcenter pt-15">
        <div class="column is-flex is-vcenter">
          <searchbar
            @search="
              searchQuery = $event;
              getData();
            "
          ></searchbar>
          <div
            class="filter__btn"
            @click="open_filter = true"
            :class="{
              'is-active': filter_query_string && filter_query_string.length
            }"
          >
            <i class="fas fa-filter"></i>
          </div>
          <div class="is-relative" style="left: 22rem; top: 2.2rem">
            <filter-dropdown
              v-if="open_filter"
              v-click-outside="() => (open_filter = false)"
              :filtersData="allFiltersData"
              @apply="
                filter_query_string = $event;
                getData();
              "
              @close="open_filter = false"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="is_loading">
      <div class="columns is-marginless pt-10">
        <list-skeleton></list-skeleton>
      </div>
    </div>
    <div v-else class="mt-3">
      <div v-if="templates.length">
        <template-header
          :sorting_key="sort_key"
          @update_sort_key="sort_key = $event"
        ></template-header>
        <div v-for="template in sorted_templates" :key="template.uid">
          <template-card
            @refresh_template_tickets="getData"
            :ticket="template"
          ></template-card>
        </div>
      </div>
    </div>
    <pagination
      :default_page_size="default_page_size"
      :current-page="currentPage"
      :page-count="pageCount"
      class="articles-list__pagination"
      @nextPage="pageChangeHandle('next')"
      @previousPage="pageChangeHandle('previous')"
      @loadPage="pageChangeHandle"
      :visiblePagesCount="visiblePagesCount"
      :totalRecords="totalRecords"
    ></pagination>
  </div>
</template>

<script>
  const queryString = require("query-string");
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import TemplateCard from "@/app/old/desktop/tickets/components/ticket-dashboard/components/templates/components/template-card";
  import ListSkeleton from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/skeletons/list-skeleton.vue";
  import TemplateHeader from "@/app/old/desktop/tickets/components/ticket-dashboard/components/templates/components/template-header.vue";
  import searchbar from "@/app/old/desktop/core/shared/components/searchbar";
  import FilterDropdown from "@/app/old/desktop/tickets/shared/components/filter-card/filter-dropdown";
  import PaginationMixin from "@/app/old/desktop/shared/mixins/pagination.mixin.vue";
  import Pagination from "@/app/old/desktop/shared/components/pagination.vue";
  import SortDropdown from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/sort-dropdown.vue";

  export default {
    mixins: [PaginationMixin],

    components: {
      TemplateCard,
      ListSkeleton,
      TemplateHeader,
      searchbar,
      FilterDropdown,
      Pagination,
      SortDropdown
    },
    data() {
      return {
        sort_by: {},
        sort_key: "",
        templates: [],
        is_loading: true,
        searchQuery: "",
        filter_query_string: "",
        open_filter: false,
        allFiltersData: {},
        default_page_size: 25
      };
    },
    created() {
      this.getData(true);
    },
    beforeDestroy() {
      this.templates = [];
    },
    computed: {
      sorted_templates() {
        if (!this.sort_key) return this.templates;
        let sort_by;
        let order = this.sort_key.startsWith("-") ? "desc" : "asc";
        let key = this.sort_key.startsWith("-")
          ? this.sort_key.slice(1, this.sort_key.length)
          : this.sort_key;
        switch (key) {
          case "name":
            sort_by = "name";
            break;
          case "createdAt":
            sort_by = "createdAt";
            break;
        }
        return _.orderBy(this.templates, [sort_by], [order]);
      }
    },
    methods: {
      async getData(loading = false) {
        try {
          this.is_loading = loading;
          let query = {
            pageSize: this.default_page_size,
            pageNumber: this.$route.query.page || 1,
            isTemplate: true,
            q: this.searchQuery
          };
          let assets_query = "";

          if (this.sort_by.key)
            query["sort"] = (this.sort_by.order ? "-" : "") + this.sort_by.key;

          let { data, headers } = await ticketService
            .getAll({
              query: queryString.stringify(query) + this.filter_query_string
            })
            .catch(err => {
              this.handleErrors(err);
            });
          this.updatePagination(headers["x-total-count"]);
          this.templates = data;
          this.is_loading = false;
        } catch (err) {
          this.is_loading = false;
          this.templates = [];
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ticket-checklists {
    // padding: 2rem;
    &-items {
      display: flex;
      margin: 4rem 0 2rem 0;
    }
    .ticket-overview-options {
      height: 3rem;
      margin: 0rem 0 1.8rem;
      // margin-bottom: 1.8rem;
    }
  }
  .ticket-checklist-card {
    background-color: #fff;
    // border-radius: 1rem;
    box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
    padding: 2rem 2rem 2rem 1.5rem;
    display: flex;

    &__right {
      width: 100%;
    }

    &__top {
      display: flex;
      align-items: center;
    }
    &__content {
      &-sec {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-top: 1rem;
        &__left {
          display: flex;
          img {
            margin-right: 0.5rem;
            border-radius: 50%;
          }
        }
      }
    }
  }
</style>
<style lang="scss" scoped>
  .column {
    padding: 1rem;
  }
</style>
