<template>
  <div class="ticket-member-expand">
    <div class="pt-10 mr-10">
      <div class="ticket-overview-options">
        <div class="ticket-overview-options__left">
          <span class="is-flex align-center">
            <sh-icon
              @click.native="$router.push({ name: 'overview' })"
              :name="'ticket-header-back'"
              class="is-16x16 mr-10 is-pointer"
            />
            <span class="ticket-overview-options__left__title">
              {{ localization("app-overview", "Overview") }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <top-header> </top-header>
    <div class="columns is-gapless">
      <div class="column is-8">
        <div class="ticket-member-card">
          <div class="ticket-member-card__header">
            <div class="ticket-member-card__header__left">
              <sh-icon :name="'ticket-card-members'" class="is-30x30 mr-10" />
              <span class="ticket-member-card__header__left__title">
                {{ localization("app-assignee", "Assignee") }}</span
              >
            </div>
            <div class="is-flex-center">
              <div
                class="is-flex-center mr-20 is-pointer"
                @click="getMembers('assignee', 'status', {})"
                @mouseover="refresh = true"
                @mouseleave="refresh = false"
              >
                <sh-icon
                  :name="'ticket-history-recents'"
                  class="is-20x20 mr-5"
                />
                <div
                  class="is-medium-12-500-14 has-opacity-5 has-text-danger"
                  v-if="refresh"
                >
                  {{ localization("app-refresh", "Refresh") }}
                </div>
                <div class="is-medium-12-500-14 has-opacity-5" v-else>
                  {{ since }} mins ago
                </div>
              </div>
            </div>
          </div>
          <div class="ticket-member-card__content has-slimscroll mt-20">
            <div class="" v-for="(count, member) in totalCount" :key="member">
              <div class="columns is-vcenter py-10">
                <div class="column is-4 is-flex is-vcenter">
                  <user-avatar
                    :custom_class="'tiny-user-avatar'"
                    :user="$store.getters.state_users[member]"
                    class="mr-10"
                    :show_tooltip="true"
                    :pxsize="30"
                  ></user-avatar>
                  <div
                    class="item-name is-medium-16-500-19 no-whitespace-wrap "
                    v-if="$store.getters.state_users[member]"
                    v-username="$store.getters.state_users[member]"
                  ></div>
                  <div v-else class="is-medium-16-500-19">
                    {{ localization("app-unknown", "Unknown") }}
                  </div>
                </div>

                <div class="column is-6 mt-10">
                  <ticket-multiple-progress
                    :values="eachCount[member]"
                    @filter_by="filter_ticket_list($event, member)"
                  ></ticket-multiple-progress>
                </div>
                <div
                  class="column is-2 is-paddingless member-total is-flex align-center is-center mr-5"
                >
                  <sh-icon :name="'ticket-tag-icon'" class="is-20x20 mr-5" />
                  <span class="is-medium-14-500-17"> {{ count }} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 ">
        <div class="ticket-member-config-card">
          <div class="ticket-member-config-header">
            {{ localization("app-configure", "Configure") }}
          </div>
          <div>
            <div class="member-config">
              <label class="is-flex is-pointer">
                <input
                  id="all"
                  :checked="true"
                  @click="
                    filter = 'All';
                    add_filter();
                  "
                  disabled
                  type="checkbox"
                  class="sh-custom-checkbox"
                />
                <label for="all" class="is-regular-14-00-22 ml-10">{{
                  localization("app-all", "All")
                }}</label>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns" style="height:4rem">
      <div class="column is-5">
        <bulk-actions-bar
          :global_select="global_select"
          @refresh-tickets="getData(true)"
        ></bulk-actions-bar>
      </div>

      <div class="column is-7">
        <div class="is-pulled-right pr-20">
          <export-dropdown :allFiltersData="extra_query"></export-dropdown>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 mt-20">
        <tickets-list :extra_query="extra_query"></tickets-list>
      </div>
    </div>
  </div>
</template>

<script>
  import TicketsList from "@/app/old/desktop/tickets/components/tickets-list/tickets-list";
  import BulkActionsBar from "@/app/old/desktop/tickets/shared/components/bulk-actions";
  import ExportDropdown from "@/app/old/desktop/tickets/components/tickets-list/components/tickets-top-filters/components/export-dropdown.vue";
  import OverviewMixin from "../../../shared/mixins/overview.mixin";
  import TicketMultipleProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-multiple-progress";
  import TopHeader from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/top-header.vue";
  import { mapState } from "vuex";

  export default {
    mixins: [OverviewMixin],

    components: {
      TicketMultipleProgress,
      TicketsList,
      BulkActionsBar,
      ExportDropdown,
      TopHeader
    },
    data() {
      return {
        eachCount: {},
        member_details: null,
        filter: "All",
        extra_query: {},
        totalCount: {}
      };
    },
    computed: {
      ...mapState("tickets", ["filters", "selected_asset", "allFiltersData"])
    },
    watch: {
      filters() {
        this.extra_query = this.create_filter_obj();
        this.getMembers("assignee", "status", this.extra_query);
      },
      selected_asset() {
        this.extra_query = this.create_filter_obj();
        this.getMembers("assignee", "status", this.extra_query);
      },
      allFiltersData() {
        this.extra_query = this.create_filter_obj();
        this.getMembers("assignee", "status", this.extra_query);
      }
    },
    created() {
      this.extra_query = this.create_filter_obj();
      this.getMembers("assignee", "status", this.extra_query);
    },

    methods: {
      async getMembers(xkey, ykey, filter) {
        this.member_details = await this.getReport(xkey, ykey, filter);
        if (this.member_details) {
          this.member_details.map(item => {
            if (this.$store.getters.state_users[item.x]) {
              this.eachCount[item.x] = item.y;
            }
          });
        }
        this.eachCount = Object.assign({}, this.eachCount);

        let tempcount = this.eachCount;

        let totalCount = {};
        Object.keys(tempcount).forEach(key => {
          let membertotal = tempcount[key].reduce((a, b) => a + b.count, 0);
          totalCount[key] = membertotal;
        });
        this.totalCount = _.chain(totalCount)
          .map((val, key) => {
            return { name: key, count: val };
          })
          .sortBy("count")
          .reverse()
          .keyBy("name")
          .mapValues("count")
          .value();
      },
      add_filter() {
        switch (this.filter) {
          case "Active":
            filter = { Active: true };
            break;
          default:
            filter = {};
        }
        this.getMembers("assignee", "status", filter);
      },
      filter_ticket_list(status, user) {
        this.$store.dispatch("tickets/add_tickets", []);
        let new_query = { status: status, takenUp: user, offset: 0 };
        this.extra_query = { ...this.extra_query, ...new_query };
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ticket-member-expand {
    padding: 1rem;
  }

  .ticket-member-config {
    &-card {
      background-color: $white;
      border-radius: 1rem;
      box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
      margin: 2rem 1rem 0 1rem;
    }
    &-header {
      border-bottom: 1px solid #f0f1f3;
      padding: 1.5rem 3rem;
      font-family: Barlow-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: $ticket-heading;
    }
  }

  .ticket-member-card {
    background-color: $white;
    border-radius: 1rem;
    box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
    margin: 2rem 1rem 0 1rem;
    padding: 1.5rem 1.5rem 3rem 1.5rem;

    &__header {
      display: flex;
      justify-content: space-between;

      &__left {
        display: flex;
        align-items: center;
        &__title {
          font-family: Barlow-Medium !important;
          font-size: 18px !important;
          font-weight: 500 !important;
          line-height: 22px !important;
          color: $ticket-heading;
        }
      }
    }

    &__content {
      max-height: 380px;
      overflow-y: auto;
      overflow-x: hidden;
      &__item {
        display: flex;
        align-items: center;
        padding: 2rem 0 0 2.5rem;

        img {
          border-radius: 50%;
        }
        .item-name {
          color: $secondary-text;
        }
        .item-desc {
          color: $primary-text;
          opacity: 0.7;
        }

        &__right {
          position: relative;
          width: 100%;
        }
      }
    }
  }
  .member-total {
    text-align: center;
  }
  .member-config:first-child {
    padding: 2rem 3rem 1rem 3rem;
  }
  .member-config {
    padding: 1rem 3rem 1rem 3rem;
  }
  .member-config:last-child {
    padding: 1rem 3rem 2rem 3rem;
  }
</style>
