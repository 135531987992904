<template>
  <div class="ticket-priority-expand">
    <div class="pt-10 mr-10">
      <div class="ticket-overview-options">
        <div class="ticket-overview-options__left">
          <span class="is-flex align-center">
            <sh-icon
              @click.native="$router.push({ name: 'overview' })"
              :name="'ticket-header-back'"
              class="is-16x16 mr-10 is-pointer"
            />
            <span class="ticket-overview-options__left__title">
              {{ localization("app-overview", "Overview") }}
            </span>
          </span>
        </div>
      </div>
    </div>
    <top-header> </top-header>
    <div class="columns is-gapless">
      <div class="column is-8">
        <div class="ticket-priority-card">
          <div class="ticket-priority-card__header">
            <div class="ticket-priority-card__header__left">
              <sh-icon :name="'ticket-card-tags'" class="is-30x30 mr-10" />
              <span class="ticket-priority-card__header__left__title">{{
                localization("app-tag", "Tag")
              }}</span>
            </div>
            <div
              class="is-flex-center"
              @mouseover="refresh = true"
              @mouseleave="refresh = false"
            >
              <div
                class="is-flex-center mr-20 is-pointer"
                @click="getTags('tags', 'status', {})"
              >
                <sh-icon
                  :name="'ticket-history-recents'"
                  class="is-20x20 mr-5"
                />
                <div
                  class="is-medium-12-500-14 has-opacity-5 has-text-danger"
                  v-if="refresh"
                >
                  {{ localization("app-refresh", "Refresh") }}
                </div>
                <div class="is-medium-12-500-14 has-opacity-5" v-else>
                  {{ since }} mins ago
                </div>
              </div>
              <!-- <div class="is-flex-center is-not-allowed">
                <sh-icon :name="'ticket-download'" class="is-20x20 mr-5" />
                <div class="is-medium-12-500-14 has-opacity-5 is-not-allowed">Export as CSV</div>
              </div> -->
            </div>
          </div>
          <div class="ticket-priority-card__content has-slimscroll-xs">
            <div v-if="is_loading" class="pt-20">
              <div v-for="i in 5" :key="i" class="mt-40">
                <div class="columns">
                  <div class="column is-1 mr-20">
                    <b-skeleton width="90%"></b-skeleton>
                  </div>
                  <div class="column is-9">
                    <b-skeleton width="75%"></b-skeleton>
                    <b-skeleton width="50%"></b-skeleton>
                  </div>
                  <div class="column is-1">
                    <b-skeleton width="90%"></b-skeleton>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else-if="
                !is_loading && (!eachCount || !Object.keys(eachCount).length)
              "
            >
              {{ localization("app-no-data", "No data") }}
            </div>
            <div
              class="ticket-priority-card__content__item"
              v-else
              v-for="(tag, index, i) in sortedTags"
              :key="index"
            >
              <div
                class="ticket-priority-card__content__item__right"
                v-if="tag && tag.length"
              >
                <div class="columns is-flex-center is-marginless">
                  <div class="column is-3 is-paddingless">
                    <div class="priority-item-name is-medium-16-500-19">
                      <span class="is-capitalized">
                        {{ tag | truncate(20) || "" }}
                      </span>
                    </div>
                  </div>
                  <div class="column is-7 is-paddingless">
                    <ticket-multiple-progress
                      :values="eachCount[tag].stats"
                      @filter_by="
                        filter_ticket_list($event, eachCount[tag].tag_id)
                      "
                    ></ticket-multiple-progress>
                  </div>

                  <div class="column is-2 is-paddingless member-total">
                    {{ eachCount[tag].total }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-4 ">
        <div class="ticket-priority-config-card">
          <div class="ticket-priority-config-header">
            {{ localization("app-configure", "Configure") }}
          </div>

          <div>
            <div class="priority-config">
              <label class="is-flex is-pointer" @click="extract_tags(false)">
                <input
                  type="radio"
                  class="sh-custom-checkbox"
                  id="extract"
                  v-model="extract"
                  :value="false"
                />
                <span class="is-medium-16-500-22 ml-10">{{
                  localization("app-exact", "Exact")
                }}</span>
              </label>
            </div>
            <div class="priority-config">
              <label class="is-flex is-pointer" @click="extract_tags(true)">
                <input
                  type="radio"
                  class="sh-custom-checkbox"
                  id="each"
                  v-model="extract"
                  :value="true"
                />
                <span class="is-medium-16-500-22 ml-10">{{
                  localization("app-each", "Each")
                }}</span>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns" style="height:4rem">
      <div class="column is-5">
        <bulk-actions-bar
          :global_select="global_select"
          @refresh-tickets="getData(true)"
        ></bulk-actions-bar>
      </div>

      <div class="column is-7">
        <div class="is-pulled-right pr-20">
          <export-dropdown :allFiltersData="extra_query"></export-dropdown>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12 mt-20">
        <tickets-list :extra_query="extra_query"></tickets-list>
      </div>
    </div>
  </div>
</template>

<script>
  import TicketsList from "@/app/old/desktop/tickets/components/tickets-list/tickets-list";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import { mapState } from "vuex";
  import BulkActionsBar from "@/app/old/desktop/tickets/shared/components/bulk-actions";
  import ExportDropdown from "@/app/old/desktop/tickets/components/tickets-list/components/tickets-top-filters/components/export-dropdown.vue";
  import TopHeader from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/top-header.vue";
  import OverviewMixin from "../../../shared/mixins/overview.mixin";

  import TicketMultipleProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-multiple-progress";
  export default {
    mixins: [OverviewMixin],

    components: {
      TicketMultipleProgress,
      TicketsList,
      BulkActionsBar,
      ExportDropdown,
      TopHeader
    },
    data() {
      return {
        refresh: false,
        allTags: [],
        eachCount: {},
        extract: true,
        extra_query: {},
        lastFetchedAt: null,
        now: new Date(),
        is_loading: false,
        sortedTags: {}
      };
    },

    watch: {
      filters() {
        this.init();
      },
      selected_asset() {
        this.init();
      },
      allFiltersData() {
        this.init();
      }
    },
    computed: {
      ...mapState(["TAGS"]),
      ...mapState("tickets", ["filters", "selected_asset", "allFiltersData"]),
      since() {
        var a = moment(this.lastFetchedAt);
        var b = moment(this.now);
        return b.diff(a, "minutes");
      }
    },
    created() {
      this.init();
    },

    methods: {
      init() {
        this.extra_query = this.create_filter_obj();
        if (this.$route.query && this.$route.query.filter == "Each") {
          this.extract = true;
        } else {
          this.extract = false;
        }
        setInterval(() => {
          this.now = Date.now();
        }, 60000);
        this.getTags("tags", "status", this.extra_query);
      },
      async getTags(xkey, ykey, filter) {
        this.is_loading = true;
        this.eachCount = {};
        this.allTags = await this.getReport(xkey, ykey, filter);
        if (this.allTags && this.TAGS && Object.keys(this.TAGS).length) {
          if (this.extract == true) {
            this.allTags.map((item, index) => {
              let name =
                this.TAGS[item.x] && this.TAGS[item.x].name
                  ? this.TAGS[item.x].name
                  : null;
              if (name) {
                return (this.eachCount[name] = {
                  tag_id: item.x,
                  stats: item.y,
                  details: this.TAGS[item.x],
                  total: _.sumBy(item.y, function(o) {
                    return o.count;
                  })
                });
              }
            });
            this.eachCount = Object.assign({}, this.eachCount);
          } else {
            this.allTags.map((item, index) => {
              let name = this.generate_tag_name(item.x);
              return (this.eachCount[name] = {
                tag_id: item.x,
                stats: item.y,
                details: this.TAGS[item.x[0]],
                total: _.sumBy(item.y, function(o) {
                  return o.count;
                })
              });
            });

            this.eachCount = Object.assign({}, this.eachCount);
          }
        }
        this.eachCount = Object.assign({}, this.eachCount);
        this.is_loading = false;
        this.eachCount = Object.assign({}, this.eachCount);
        let eachTagTotal = _.mapValues(this.eachCount, function(o) {
          return o.total;
        });
        let sortedTags = Object.entries(eachTagTotal).sort(
          (a, b) => b[1] - a[1]
        );
        this.sortedTags = sortedTags.map(o => o[0]);
      },
      generate_tag_name(val) {
        if (val) {
          return _.flatten(
            val.map(a => {
              if (this.TAGS && this.TAGS[a]) {
                return this.TAGS[a].name ? this.TAGS[a].name : a;
              } else {
                return a;
              }
            })
          );
        }
      },

      async getReport(xkey, ykey, filter = {}) {
        this.lastFetchedAt = new Date().toISOString();
        let allFilters = {
          ...{ organization: this.$store.state.current_organization.uid },
          ...filter
        };
        let body = {
          filters: allFilters,
          x: {
            key: xkey,
            extract: this.extract
          },
          y: {
            key: ykey
          }
        };
        return await ticketService.get_report({ body }).catch(err => {
          this.handleErrors(err);
        });
      },

      extract_tags(e) {
        this.extract = e;
        this.getTags("tags", "status", this.extra_query);
      },
      filter_ticket_list(status, tag) {
        this.$store.dispatch("tickets/add_tickets", []);
        let new_query = { status: status, tag: tag, offset: 0 };
        this.extra_query = { ...this.extra_query, ...new_query };
        console.log("extra query", this.extra_query);
      }
    }
  };
</script>

<style lang="scss" scoped>
  .ticket-priority-expand {
    padding: 1rem;
  }

  .ticket-priority-config {
    &-card {
      background-color: $white;
      border-radius: 1rem;
      box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
      margin: 2rem 1rem 0 1rem;
    }
    &-header {
      border-bottom: 1px solid #f0f1f3;
      padding: 1.5rem 3rem;
      font-family: Barlow-Medium;
      font-size: 18px;
      font-weight: 500;
      line-height: 22px;
      color: $ticket-heading;
    }
  }

  .ticket-priority-card {
    background-color: $white;
    border-radius: 1rem;
    box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
    margin: 2rem 1rem 0 1rem;
    padding: 1.5rem 1.5rem 3rem 1.5rem;

    &__header {
      display: flex;
      justify-content: space-between;

      &__left {
        display: flex;
        align-items: center;
        &__title {
          font-family: Barlow-Medium !important;
          font-size: 18px !important;
          font-weight: 500 !important;
          line-height: 22px !important;
          color: $ticket-heading;
        }
      }
    }

    &__content {
      max-height: 380px;
      overflow-y: auto;
      overflow-x: hidden;
      &__item {
        display: flex;
        align-items: center;
        padding: 2rem 0 2rem 2.5rem;

        img {
          border-radius: 50%;
        }
        .priority-item-name {
          display: flex;
          align-items: center;
          color: $secondary-text;
          // padding-bottom: 0.5rem;
        }
        .item-desc {
          color: $primary-text;
          opacity: 0.7;
          // min-width: 15rem;
          // max-width: 15rem;
        }

        &__right {
          position: relative;
          width: 100%;
        }
      }
    }
  }
  .member-total {
    text-align: center;
  }
  .priority-config:first-child {
    padding: 2rem 3rem 1rem 3rem;
  }
  .priority-config {
    padding: 1rem 3rem 1rem 3rem;
  }
  .priority-config:last-child {
    padding: 1rem 3rem 2rem 3rem;
  }
</style>
