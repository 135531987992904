<template>
  <div>
    <div class="ticket-overview-options">
      <div class="ticket-overview-options__left">
        <span class="is-flex align-center">
          <span class="ticket-overview-options__left__title">{{
            localization("app-reports", "Reports")
          }}</span>
        </span>
      </div>
      <div class="ticket-overview-options__right">
        <span
          class="add-new-report"
          @click="create_report_popup()"
          v-if="reportData && reportData.length"
        >
          <span class="is-semiBold-14-600-17 has-text-blue">{{
            localization("app-add-a-report", "Add a Report")
          }}</span>
        </span>
      </div>
    </div>
    <div v-if="is_loading" class="card padding-20 margin-20">
      <section class="my-20" v-for="i in 6" :key="i">
        <b-skeleton width="20%" :animated="animated"></b-skeleton>
        <b-skeleton width="40%" :animated="animated"></b-skeleton>
        <b-skeleton width="80%" :animated="animated"></b-skeleton>
        <b-skeleton :animated="animated"></b-skeleton>
      </section>
    </div>

    <div v-else-if="!is_loading && reportData && reportData.length">
      <grid-layout
        :layout.sync="reportData"
        :col-num="3"
        :row-height="15"
        :is-draggable="true"
        :is-resizable="true"
        :is-mirrored="false"
        :vertical-compact="true"
        :margin="[15, 15]"
        :use-css-transforms="true"
        class="is-relative"
      >
        <grid-item
          v-for="item in reportData"
          :x="item.x"
          :y="item.y"
          :w="item.w"
          :h="item.h"
          :i="item.i"
          :key="item.i"
          :minH="15"
          @moved="update_data"
          @resized="update_data"
        >
          <report-graph
            :width="item.w"
            :card="item.report"
            :index="item.i"
            @update-card="update_graph($event, item.i)"
            @delete-report="delete_graph(item.i)"
          ></report-graph>
        </grid-item>
      </grid-layout>
    </div>

    <div v-else class="no-report-card">
      <img src="@/assets/illustrations/no-report-illustration.svg" alt />
      <span class="mt-30 is-medium-20-500-24 has-primary-text">{{
        localization("app-no-reports", "No Reports")
      }}</span>
      <div
        class="add-report is-semiBold-14-600-17"
        @click="create_report_popup()"
      >
        {{ localization("app-add-a-report", "Add a Report") }}
      </div>
    </div>
  </div>
</template>

<script>
  import CreateReportComponent from "./components/create-report-popup";
  import ReportGraph from "./components/report-graph";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import VueGridLayout from "vue-grid-layout";

  export default {
    components: {
      GridLayout: VueGridLayout.GridLayout,
      GridItem: VueGridLayout.GridItem,
      CreateReportComponent,
      ReportGraph
    },
    data() {
      return {
        reportData: [],
        is_loading: false,
        highchart_loaded: false,
        highchart_more_loaded: false,
        highchart_fullscreen_loaded: false,
        highchart_nodata_loaded: false
      };
    },
    created() {
      this.get_all_reports();
    },

    methods: {
      async get_all_reports() {
        this.is_loading = true;
        let { reportData } = await ticketService
          .get_chart_reports({})
          .catch(err => this.handleErrors);

        this.reportData = [...(reportData || [])];
        this.is_loading = false;
      },
      create_report_popup() {
        this.$modal.show(
          CreateReportComponent,
          {
            add_report_filter: body => {
              let id = this.get_uuid();

              if (id) {
                let new_chart = {
                  x: 0,
                  y: 0,
                  w: 2,
                  h: 20,
                  i: id,
                  report: body,
                  moved: false
                };
                this.reportData.unshift(new_chart);
                let res = ticketService
                  .update_chart_reports({
                    body: { reportData: this.reportData }
                  })
                  .catch(err => this.handleErrors);
                if (res)
                  this.$toast.show(
                    this.localization(
                      "app-report-added-successfully",
                      "Report added successfully"
                    ),
                    "",
                    this.$store.state.izitoast_options.appSuccess
                  );
              }
            }
          },
          {
            width: "48%",
            scrollable: false,
            height: "auto"
          }
        );
      },

      get_uuid(increment = 1) {
        let unique = this.reportData.length + increment;
        let not_unique = _.some(this.reportData, ["i", unique]);
        if (not_unique) {
          return this.get_uuid(increment + 1);
        } else {
          return unique;
        }
      },
      async update_data() {
        let res = await ticketService
          .update_chart_reports({ body: { reportData: this.reportData } })
          .catch(err => this.handleErrors);
        if (res)
          this.$toast.show(
            "Report updated successfully",
            "",
            this.$store.state.izitoast_options.appSuccess
          );

        this.reportData = [...(res.data.reportData || [])];
      },
      update_graph(card, index) {
        for (let j = 0; j < this.reportData.length; j++) {
          if (this.reportData[j].i == index) this.reportData[j].report = card;
        }
        this.reportData = Object.assign([], this.reportData);
        this.update_data();
      },
      delete_graph(index) {
        this.reportData = _.remove(this.reportData, report => {
          return report.i != index;
        });

        let res = ticketService
          .update_chart_reports({ body: { reportData: this.reportData } })
          .catch(err => this.handleErrors);
      }
    }
  };
</script>

<style lang="scss">
  .vue-grid-item.cssTransforms {
    background-color: $white;
    border-radius: 1rem;
    box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
    padding: 1.5rem;
    &:hover {
      box-shadow: 0 0 10px 0 rgba(37, 133, 211, 0.3);
    }
  }

  .highchart-chart {
    height: auto;
    width: auto;
    position: relative;
    z-index: 1000;
    .report-options {
      visibility: hidden;
      position: absolute;
      z-index: 10000;
      right: 0;
    }
    &:hover {
      .report-options {
        visibility: visible;
      }
    }
  }
  .add-new-report {
    cursor: pointer;
    height: 3.6rem;
    width: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $primary;
    border-radius: 4px;
    background-color: $white;
    color: $primary;
    margin-left: 2rem;
  }
  .ticket-overview-options {
    margin: 1.5rem 1.5rem 0;
  }

  .no-report-card {
    height: 50rem;
    border-radius: 10px;
    box-shadow: 0 0 3px 0 rgba(23, 30, 72, 0.2);
    background-color: $white;
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .add-report {
      margin-top: 1.3rem;
      width: 141px;
      height: 44px;
      border-radius: 6px;
      box-shadow: 0 2px 5px 0 rgba(37, 133, 211, 0.3);
      border: solid 1px $primary;
      background-color: $white;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $primary;
      cursor: pointer;
    }
  }
</style>
