<template>
  <div>
    <div class="ticket-checklists-items">
      <span class="is-flex is-pointer px-20 ">
        <sh-icon
          @click.native="open_today = -1"
          v-if="open_today == when"
          :name="'ticket-checklist-expand'"
          class="is-24x24"
        />
        <sh-icon
          v-else
          @click.native="open_today = when"
          :name="'ticket-checklist-collapse'"
          class="is-24x24"
        />
        <span class="is-medium-16-500-19">{{
          schedule_times(when)
        }}</span></span
      >
    </div>
    <div class="" v-if="open_today == when">
      <schedule-header v-if="index == 0"></schedule-header>
      <div v-for="ticket in tickets" :key="ticket.uid">
        <schedule-card :ticket="ticket"> </schedule-card>
      </div>
    </div>
  </div>
</template>
<script>
  import ScheduleCard from "@/app/old/desktop/tickets/components/ticket-dashboard/components/schedules/components/schedule-card";
  import ScheduleHeader from "@/app/old/desktop/tickets/components/ticket-dashboard/components/schedules/components/dashboard-schedule-header";

  export default {
    props: ["tickets", "when", "index"],
    components: {
      ScheduleCard,
      ScheduleHeader
    },
    data() {
      return {
        open_today: this.when
      };
    },
    methods: {
      schedule_times(date) {
        let scheduleKey;

        switch (date) {
          case "0":
            scheduleKey = this.localization("app-yearly", "Yearly");
            break;
          case "1":
            scheduleKey = this.localization("app-monthly", "Monthly");
            break;
          case "2":
            scheduleKey = this.localization("app-weekly", "Weekly");
            break;
          case "3":
            scheduleKey = this.localization("app-daily", "Daily");
            break;

          default:
            scheduleKey = this.localization("app-stopped", "Stopped");
            break;
        }
        return scheduleKey;
      }
    }
  };
</script>
