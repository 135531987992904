var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ticket-card ticket-card-height"},[_c('div',{staticClass:"ticket-card-options"},[_c('div',{staticClass:"ticket-card-options__left is-pointer",on:{"click":function($event){return _vm.getStaleTickets()},"mouseover":function($event){_vm.refresh = true},"mouseleave":function($event){_vm.refresh = false}}},[_c('sh-icon',{staticClass:"is-24x24 mr-5",attrs:{"name":'ticket-history-recents'}}),(_vm.refresh)?_c('div',{staticClass:"is-medium-12-500-14 has-opacity-5 has-text-danger"},[_vm._v(" "+_vm._s(_vm.localization("app-refresh", "Refresh"))+" ")]):_c('div',{staticClass:"is-medium-12-500-14 has-opacity-5"},[_vm._v(" "+_vm._s(_vm.since)+" mins ago ")])],1),_c('div',{staticClass:"ticket-card-options__right",class:[
        !_vm.staleTickets || !_vm.staleTickets.length ? 'has-no-access' : null
      ]},[_c('sh-icon',{staticClass:"is-24x24 mr-15 is-pointer is-hidden",attrs:{"name":'ticket-download'}}),_c('sh-icon',{staticClass:"is-24x24 is-pointer",attrs:{"name":'ticket-expand-maximize'},nativeOn:{"click":function($event){return _vm.$router.push({
            name: 'filtered-tickets-list',
            params: { filters: Object.assign({}, _vm.query, { name: _vm.selected }) }
          })}}})],1)]),_c('div',{staticClass:"ticket-card-heading"},[_c('div',{staticClass:"ticket-card-heading__icon"},[_c('sh-icon',{staticClass:"is-50x50",attrs:{"name":'ticket-card-stale'}})],1),_c('div',{staticClass:"ticket-card-heading__title "},[_vm._v(" "+_vm._s(_vm.localization("app-stale", "Stale"))+" ")]),_c('div',{staticClass:"ticket-card-heading__subtitle"},[_c('div',{staticClass:"ticket-dropdown",on:{"click":function($event){_vm.open_ticket_dropdown = !_vm.open_ticket_dropdown}}},[_c('span',{staticClass:"option-value is-medium-14-500-17"},[_vm._v(_vm._s(_vm.selected_filter_name))]),_c('sh-icon',{staticClass:"is-16x16 is-pointer",attrs:{"name":("" + (_vm.open_ticket_dropdown ? 'expand' : 'collapse'))}}),(_vm.open_ticket_dropdown)?_c('ticket-dropdown',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(function () { return (_vm.open_ticket_dropdown = false); }),expression:"() => (open_ticket_dropdown = false)"}],attrs:{"selected_filter":_vm.selected,"dropdown_items":_vm.dropdown_items,"dropdown_sec_items":_vm.dropdown_sec_items},on:{"addFilter":function($event){_vm.getStaleTickets($event.query);
            _vm.selected = $event.selected;}}}):_vm._e()],1)])]),(_vm.staleTickets && _vm.staleTickets.length)?_c('div',{staticClass:"ticket-card-priority"},_vm._l((_vm.staleTickets),function(stale){return _c('div',{key:stale.uid,staticClass:"ticket-db-list"},[_c('div',{staticClass:"ticket-db-list__details"},[_c('div',{staticClass:"ticket-db-list__details__topheader is-medium-16-500-19"},[_vm._v(" "+_vm._s(stale.name)+" ")]),_c('div',{staticClass:"ticket-db-list__details__top is-medium-16-500-19"},[_c('div',{staticClass:"ticket-db-list__details__left"},[_c('span',{staticClass:"is-medium-12-500-14",class:'status status__' + stale.status},[_vm._v(" "+_vm._s(_vm.status_options[stale.status - 1])+" ")])]),_c('div',{staticClass:"ticket-db-list__details__right"},[_c('div',{staticClass:"is-flex align-center"},[(stale.commentsCount)?_c('span',{staticClass:"ticket-db-list__details__right__item"},[_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":'ticket-comments'}})],1):_vm._e(),(stale.attachmentsCount)?_c('span',{staticClass:"ticket-db-list__details__right__item"},[_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":'ticket-attachements'}}),_c('span',{staticClass:"ticket-db-list__details__right__item__name"},[_vm._v(_vm._s(stale.attachmentsCount))])],1):_vm._e(),(stale.checklistsStats)?_c('span',{staticClass:"ticket-db-list__details__right__item"},[_c('sh-icon',{staticClass:"is-18x18",attrs:{"name":'ticket-checklists'}}),_c('span',{staticClass:"ticket-db-list__details__right__item__name"},[_vm._v(" "+_vm._s((stale.checklistsStats && stale.checklistsStats.checkedItems) || 0)+"/"+_vm._s((stale.checklistsStats && stale.checklistsStats.totalItems) || 0)+" ")])],1):_vm._e()])])]),(stale.checklistsStats)?_c('div',{staticClass:"ticket-db-list__details__bottom stale-progress"},[_c('ticket-progress',{attrs:{"total":(stale.checklistsStats && stale.checklistsStats.totalItems) || 0,"value":(stale.checklistsStats && stale.checklistsStats.checkedItems) ||
                0,"valueClass":'stale-progress-value'}})],1):_vm._e()])])}),0):_c('div',[_c('p',{staticClass:"is-regular-14-500-17 has-text-centered has-opacity-8 py-20"},[_vm._v(" "+_vm._s(_vm.localization( "app-no-tickets-found-with-the-selected-filters", "No tickets found with the selected filters!" ))+" ")])]),_c('div',{staticClass:"ticket-card-showmore is-medium-14-500-17 is-pointer is-flex align-center",class:[
      !_vm.staleTickets || !_vm.staleTickets.length
        ? 'has-no-access has-text-grey'
        : null
    ],on:{"click":function($event){return _vm.$router.push({
        name: 'filtered-tickets-list',
        params: { filters: Object.assign({}, _vm.query, { name: _vm.selected }) }
      })}}},[_c('span',[_vm._v(_vm._s(_vm.localization("app-show-more", "Show more")))]),(!_vm.staleTickets || !_vm.staleTickets.length)?_c('sh-icon',{staticClass:"is-12x12 mt-2 ml-5",attrs:{"name":'right-arrow-grey'}}):_c('img',{staticClass:"ml-5 mt-2",attrs:{"src":require("@/assets/icons/tickets/showmore-arrow.png"),"alt":""}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }