<template>
  <div
    class="ticket-list-item"
    @mouseenter="hover = true"
    @mouseleave="dropdown ? null : (hover = false)"
  >
    <div class="ticket-list">
      <div class="columns ">
        <div class="column is-4 is-flex">
          <div
            class="is-pointer ml-25 mr-10"
            :class="{ 'is-vhidden': !hover && !ticket.bookmark }"
            @click="toggle_bookmark()"
          >
            <sh-icon
              :name="ticket.bookmark ? 'bookmarked-highlight' : 'bookmark'"
              class="is-18x18"
            />
          </div>
          <div>
            <div
              class="is-medium-16-500-19 no-whitespace-wrap is-flex is-vcenter is-relative"
            >
              <p class="is-pointer has-text-dark mb-5">
                <span
                  class="ticket-name-text"
                  @click="
                    $router.push({
                      name: 'schedule-details',
                      params: { id: ticket.schedule.uid, ticket: ticket.uid }
                    })
                  "
                  v-tooltip="
                    ticket.name && ticket.name.length > 30 ? ticket.name : null
                  "
                  v-if="!edit_ticket_name"
                  >{{ ticket.name | truncate(30) }}</span
                >

                <input
                  v-else-if="edit_ticket_name"
                  v-click-outside="() => (edit_ticket_name = false)"
                  type="text"
                  name="name"
                  id="ticket"
                  class="input is-inline-input is-medium-16-500-19 ticket-input"
                  :placeholder="ticket.name"
                  v-focus
                  v-model="ticket.name"
                  @keyup.esc="edit_ticket_name = -1"
                  @keyup.enter="update_ticket_name"
                />
              </p>
            </div>
            <span class="is-medium-14-500-17 has-opacity-7">
              {{ localization("app-next-occurence", "Next Occurence") }}
            </span>
            <span class="is-semiBold-14-600-17">
              {{
                next_occurrence
                  ? next_occurrence == "-"
                    ? next_occurrence
                    : next_occurrence == "0"
                    ? localization("app-today", "Today")
                    : next_occurrence == "1"
                    ? localization("app-today", "Tomorrow")
                    : `${localization(
                        "app-in",
                        "in"
                      )} ${next_occurrence} ${localization("app-days", "days")}`
                  : localization("app-not-available", "*Not available")
              }}
            </span>
          </div>
          <div :class="[edit_ticket_name ? '' : 'left-10']" class="is-relative">
            <sh-icon
              :name="[edit_ticket_name ? 'tick-set-complete' : 'pen']"
              :class="{ 'is-hidden': !hover || !ticket.writeAccess }"
              class=" is-16x16 is-inline is-pointer"
              @click.native="
                edit_ticket_name
                  ? update_ticket_name()
                  : (edit_ticket_name = true)
              "
            ></sh-icon>
          </div>
        </div>
        <div class="column is-2 is-flex align-center is-between">
          <p class="is-flex is-vcenter">
            <user-avatar
              :user="ticket.owner"
              :pxsize="30"
              :custom_class="'tiny-user-avatar'"
              class="mr-7"
              :show_tooltip="true"
            ></user-avatar>
            <span
              class=" username-truncate is-medium-14-500-17 has-text-new-grey"
              v-username="ticket.owner"
            ></span>
          </p>
        </div>
        <div
          class="column is-2 is-flex align-center is-between is-medium-14-500-17 has-text-new-grey"
        >
          {{
            new Date(ticket.createdAt).toLocaleString(locale, {
              month: "short",
              day: "numeric",
              year: "numeric",
              hour: "numeric",
              minute: "2-digit"
            })
          }}
        </div>
        <div class="column is-3 is-vcenter is-flex">
          <tags-dropdown
            :service="ticketService"
            :tags="ticket.tags"
            :resourceId="ticket.uid"
            :number_of_tags_to_display="3"
            :write_access="ticket.writeAccess"
            :display_on_hover="!hover"
            :complete="update_ticket"
          />
        </div>
        <div
          class="column is-narrow is-pointer "
          :class="{ 'is-hidden': !hover }"
          v-click-outside="() => (dropdown ? (dropdown = false) : null)"
        >
          <div class="mt-15">
            <sh-icon
              :name="'3-dots-menu'"
              class="is-20x20 mr-10"
              @click.native="dropdown = true"
            />
          </div>
          <div>
            <div class="asset-dropdown ">
              <div class="asset-dropdown-content" v-if="dropdown">
                <p @click="edit_ticket_name = true">
                  <sh-icon
                    :name="'edit-small'"
                    class="is-24x24 mr-10"
                  ></sh-icon>
                  <span class="no-whitespace-wrap"
                    >{{ localization("app-edit", "Edit") }}
                  </span>
                </p>

                <p
                  @click="
                    showDeleteModal = true;
                    $modal.show(`delete-schedule-${ticket.uid}`);
                  "
                >
                  <sh-icon
                    :name="'delete-small'"
                    class="is-24x24 mr-10"
                  ></sh-icon>
                  <span class="no-whitespace-wrap">{{
                    localization("app-delete", "Delete")
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <prompt-modal
      :name="`delete-modal-${ticket.uid}`"
      v-if="showDeleteModal"
      :heading="ticket.name"
      :iconPath="require('@/assets/icons/prompt/delete.svg')"
      :footer="true"
      :cancelString="'Cancel'"
      :confirmString="'Delete'"
      @cancel="$modal.hide(`delete-modal-${ticket.uid}`)"
      @confirm="delete_schedule"
      @closed="$modal.hide(`delete-modal-${ticket.uid}`)"
    >
      <div class="prompt-modal__content__text" slot="content">
        {{
          localization(
            "app-delete-schedule-confirmation",
            "Are you sure you want to delete the Schedule?"
          )
        }}
      </div>
    </prompt-modal>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import { RRule, rrulestr } from "rrule";
  import TicketProgress from "@/app/old/desktop/tickets/components/ticket-dashboard/shared/filters/ticket-progress.vue";
  import tagsDropdown from "@/app/old/desktop/shared/components/tags/type-tag/type-tag";
  import { ticketService } from "@/app/old/desktop/shared/services/";
  import PromptModal from "@/app/old/desktop/shared/components/prompt-modal";

  export default {
    props: ["ticket"],
    data() {
      return {
        edit_ticket_name: false,
        hover: false,
        dropdown: false,
        showDeleteModal: false,
        ticketService: ticketService
      };
    },
    components: {
      TicketProgress,
      tagsDropdown,
      PromptModal
    },
    computed: {
      ...mapState(["language"]),
      locale() {
        let lang;
        switch (this.language) {
          case "es":
            lang = "es-US";
            break;
          case "ja":
            lang = "ja-JP";
            break;
          default:
            lang = "en-US";
            break;
        }
        return lang;
      },
      recurrence_text() {
        if (
          this.ticket &&
          this.ticket.schedule &&
          this.ticket.schedule.rrule != null
        ) {
          return new RRule.fromString(this.ticket.schedule.rrule).toText();
        } else {
          return "";
        }
      },
      next_occurrence() {
        if (
          this.ticket &&
          this.ticket.schedule &&
          this.ticket.schedule.rrule != null
        ) {
          let after = new RRule(
            RRule.parseString(this.ticket.schedule.rrule)
          ).after(new Date());
          if (after) {
            let a = moment(after);
            let b = moment(new Date());
            let number_of_days = a.diff(b, "days");
            return _.toString(number_of_days);
          } else {
            return "-";
          }
        } else {
          return null;
        }
      }
    },
    methods: {
      toggle_bookmark() {
        this.ticket.bookmark = !this.ticket.bookmark;
        this.update_ticket_value({
          bookmark: this.ticket.bookmark
        });
      },

      update_ticket_name() {
        this.update_ticket_value({
          name: this.ticket.name
        });
        this.edit_ticket_name = false;
      },
      delete_schedule() {
        this.update_ticket_value({
          schedule: null
        });
        this.$parent.$emit("deleted-schedule", this.ticket.uid);
        this.$modal.hide(`delete-modal-${this.ticket.uid}`);
      },
      async update_ticket_value(payload) {
        await ticketService
          .patch({
            id: this.ticket.uid,
            body: payload
          })
          .catch(err => this.handleErrors(err, true));
      }
    }
  };
</script>

<style lang="scss" scoped>
  .schedule-occurrence {
    opacity: 0.7;
    color: $primary-text;

    &-in {
      margin-left: 0.7rem;
      color: $primary-text;
    }
  }
  .schedule-stopped {
    color: $schedule-stopped;
  }
  .schedule-desc {
    font-family: Barlow-Regular !important;
    font-size: 14px !important;
    font-weight: normal !important;
  }
  .left-10 {
    left: 10rem;
  }
</style>
