const FormsDashboard = () =>
  import(/* webpackChunkName: "forms" */ '../components/dashboard/dashboard')
const FormsDashboardForm = () =>
  import(/* webpackChunkName: "forms" */ '../components/dashboard/forms/forms')
const SubmissionDashboard = () =>
  import(
    /* webpackChunkName: "forms" */ '../components/dashboard/submissions/submission'
  )
const FormCreate = () =>
  import(
    /* webpackChunkName: "forms" */ '../components/form/create/form-create'
  )
const FormSubmission = () =>
  import(
    /* webpackChunkName: "forms" */ '../components/form/submission/form-submission'
  )
const FormSubmissionComponent = () =>
  import(
    /* webpackChunkName: "forms" */ '../components/form/submission/form-submission-component'
  )
const ReportsDashboard = () =>
  import(
    /* webpackChunkName: "forms" */ '@/app/old/desktop/forms/components/dashboard/reports/reports.vue'
  )
const FormReport = () =>
  import(
    /* webpackChunkName: "forms" */ '../components/form/reports/form-report'
  )
const FormPreview = () =>
  import(
    /* webpackChunkName: "forms" */ '../components/form/submission/form-submit'
  )
const Forms = () => import(/* webpackChunkName: "forms" */ '../forms')
const Form = () =>
  import(/* webpackChunkName: "forms" */ '../components/form/form')
const Sidebar = () =>
  import(
    /* webpackChunkName: "forms" */ '@/app/old/desktop/shared/components/sidebar.vue'
  )
const Navbar = () =>
  import(
    /* webpackChunkName: "forms" */ '@/app/old/desktop/shared/components/navbar/navbar.vue'
  )
// import FormsDashboard from '../components/dashboard/dashboard';
// import FormsDashboardForm from '../components/dashboard/forms/forms';
// import SubmissionDashboard from '../components/dashboard/submissions/submission';

// import FormCreate from '../components/form/create/form-create';
// import FormSubmission from '../components/form/submission/form-submission';
// import FormSubmissionComponent from '../components/form/submission/form-submission-component';
// import ReportsDashboard from '@/app/old/desktop/forms/components/dashboard/reports/reports.vue';

// import FormReport from '../components/form/reports/form-report';
// import FormPreview from '../components/form/submission/form-submit';
// import Forms from '../forms';
// import Form from '../components/form/form';
// import Sidebar from '@/app/old/desktop/shared/components/sidebar.vue';
// import Navbar from '@/app/old/desktop/shared/components/navbar/navbar.vue';

const Routes = [
  {
    path: '/form/:form',
    name: 'formPreview',
    components: {
      default: FormPreview
    },
    meta: {
      title: 'Forms',
      mini_sidebar: true,
      requiresAuth: true
    }
  },
  {
    path: '/form/:form/:submission',
    name: 'formPreviewSubmission',
    components: {
      default: FormPreview
    },
    meta: {
      title: 'Forms',
      mini_sidebar: true,
      requiresAuth: true
    }
  },
  {
    path: '/forms',
    name: 'forms',
    redirect: { name: 'formsDashboard' },
    components: {
      default: Forms,
      left: Sidebar,
      top: Navbar
    },
    meta: {
      title: 'Forms',
      mini_sidebar: true,
      requiresAuth: true
    },
    children: [
      {
        path: '/forms/dashboard',
        name: 'formsDashboard',
        redirect: { name: 'formsSubmisssionDashboard' },
        components: {
          default: FormsDashboard,
          left: Sidebar,
          top: Navbar
        },
        meta: {
          title: 'Forms',
          mini_sidebar: true,
          requiresAuth: true
        },
        children: [
          {
            path: '/forms/dashboard/forms',
            name: 'formsDashboardForms',
            components: {
              default: FormsDashboardForm,
              left: Sidebar,
              top: Navbar
            },
            meta: {
              title: 'Forms',
              mini_sidebar: true,
              requiresAuth: true
            }
          },
          {
            path: '/forms/dashboard/submission',
            name: 'formsSubmisssionDashboard',
            components: {
              default: SubmissionDashboard,
              left: Sidebar,
              top: Navbar
            },
            meta: {
              title: 'Forms',
              mini_sidebar: true,
              requiresAuth: true
            }
          },
          {
            path: '/forms/dashboard/reports',
            name: 'formsReportsDashboard',
            components: {
              default: ReportsDashboard,
              left: Sidebar,
              top: Navbar
            },
            meta: {
              title: 'Forms',
              mini_sidebar: true,
              requiresAuth: true
            }
          }
        ]
      },
      {
        path: '/forms/:form',
        name: 'form',
        redirect: '/forms/:form/create',
        components: {
          default: Form,
          left: Sidebar,
          top: Navbar
        },
        meta: {
          title: 'Forms',
          mini_sidebar: true,
          requiresAuth: true
        },
        children: [
          {
            path: '/forms/:form/create',
            name: 'formCreate',
            components: {
              default: FormCreate,
              left: Sidebar,
              top: Navbar
            },
            meta: {
              title: 'Forms',
              mini_sidebar: true,
              requiresAuth: true
            }
          },
          {
            path: '/forms/:form/submission',
            name: 'formSubmission',
            components: {
              default: FormSubmission,
              left: Sidebar,
              top: Navbar
            },
            meta: {
              title: 'Forms',
              mini_sidebar: true,
              requiresAuth: true
            },
            children: [
              {
                path: '/forms/:form/submission/:submission',
                name: 'formSubmissionComponent',
                components: {
                  default: FormSubmissionComponent,
                  left: Sidebar,
                  top: Navbar
                },
                meta: {
                  title: 'Forms',
                  mini_sidebar: true,
                  requiresAuth: true
                }
              }
            ]
          },
          {
            path: '/forms/:form/report',
            name: 'formReport',
            components: {
              default: FormReport,
              left: Sidebar,
              top: Navbar
            },
            meta: {
              title: 'Forms',
              mini_sidebar: true,
              requiresAuth: true
            }
          }
        ]
      }
    ]
  }
]

export default Routes
