<template>
  <div class="is-relative">
    <div
      class="show-project-icon"
      @click="show_projects = !show_projects"
      v-click-outside="() => (hover = false)"
    >
      <sh-icon
        v-if="!show_projects"
        :name="'group-right-arrow'"
        class="is-15x15"
      ></sh-icon>
      <sh-icon
        v-else
        :name="'group-right-arrow-expand'"
        class="is-15x15"
      ></sh-icon>
    </div>
    <div
      class="columns"
      @mouseleave="close_dropdown(item.uid)"
      @mouseenter="hover = true"
    >
      <div class="column is-4">
        <router-link
          :to="{ name: 'group-details', params: { id: item.uid } }"
          class="has-text-theme-black"
        >
          <main-details-media
            :truncate_value="
              $route.name == 'asset-details-groups' ||
              $route.name == 'container-details'
                ? '25'
                : '35'
            "
            :show_tooltip="true"
            :info="{
              name: item.name,
              subtext: item.project_counts + ' Projects',
              name_class: 'is-semiBold-16-600-19 name-highlights-on-hover',
              subtext_class: 'is-medium-14-500-17 has-opacity-7 mt-5',
              properties: item.properties
            }"
          ></main-details-media>
        </router-link>
      </div>
      <!-- <div class="column is-flex align-center " :class="[$route.name !== 'asset-details-groups' ? 'is-3' : 'is-4']">
        <user-avatar :user="item.owner" :pxsize="30" class="mr-10"></user-avatar>
        <span class="is-medium-14-500-17 has-text-new-grey truncate-text is-width-15" v-username="item.owner"></span>
      </div> -->
      <div class="column" v-if="$route.name !== 'asset-details-groups'">
        <primary-tag
          :tags="item.asset ? [item.asset] : null"
          :type="'asset'"
        ></primary-tag>
      </div>
      <div class="column" v-if="$route.name !== 'container-details'">
        <primary-tag
          :tags="item.container ? [item.container] : null"
          :type="'container'"
        ></primary-tag>
      </div>
      <div class="column is-flex is-between align-center">
        <div class="column" v-click-outside="() => (cal_active = false)">
          <p
            class="is-marginless is-medium-16-500-19 no-whitespace-wrap  is-pointer is-flex is-vcenter"
            v-if="item.properties && item.properties.date"
            @click="
              cal_active = !cal_active;
              create_cal();
            "
          >
            {{ new Date(item.properties.date).toLocaleDateString() }}
            <span class="ml-5" @click.stop="remove_date()" v-if="hover">
              <sh-icon :name="'close'" class="is-12x12"></sh-icon>
            </span>
          </p>
          <p
            v-else
            class="is-marginless is-regular-14-00-17 has-text-blue-grey is-pointer"
            @click="
              cal_active = !cal_active;
              create_cal();
            "
          >
            {{ localization("app-set-date", "Set Date") }}
          </p>
          <div v-show="cal_active">
            <div :id="`${'group_list_cal-' + item.uid}`"></div>
          </div>
        </div>

        <div>
          <transfer-owner
            ref="groupListTransferOwner"
            :name="item.name"
            :service="groupService"
            :id="item.uid"
            @update_user="$emit('update-group-owner', $event)"
          ></transfer-owner>
        </div>
        <div class="column ">
          <div
            :class="{ 'is-hidden': !hover && !dropdown }"
            class="margin-auto"
          >
            <div
              class="asset-dropdown "
              v-click-outside="() => (dropdown ? (dropdown = !dropdown) : null)"
            >
              <div
                class="asset-dropdown-selection"
                @click="dropdown = !dropdown"
                :class="{
                  'is-hidden': !hover && !dropdown
                }"
              >
                <sh-icon
                  :name="'3-dots-menu'"
                  class="is-20x20 ml-auto"
                ></sh-icon>
              </div>
              <div
                class="asset-dropdown-content"
                style="right:00"
                v-if="dropdown"
              >
                <p @click="$emit('edit')">
                  <sh-icon
                    :name="'edit-small'"
                    class="is-24x24 mr-10"
                  ></sh-icon>
                  <span class="no-whitespace-wrap"
                    >{{ localization("app-edit-group", "Edit Group") }}
                  </span>
                </p>

                <p @click="$refs.groupListTransferOwner.open_popup()">
                  <sh-icon
                    :name="'transfer-2'"
                    class="is-24x24 mr-10"
                  ></sh-icon>
                  <span class="no-whitespace-wrap">
                    {{
                      localization(
                        "app-transfer-ownership",
                        "Transfer ownership"
                      )
                    }}</span
                  >
                </p>
                <p @click="showDeleteModal = true">
                  <sh-icon
                    :name="'delete-small'"
                    class="is-24x24 mr-10"
                  ></sh-icon>
                  <span class="no-whitespace-wrap">{{
                    localization("app-delete-group", "Delete Group")
                  }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <keep-alive>
      <group-project-list
        v-if="show_projects"
        :group_id="item.uid"
        @update_project_count="item.project_counts = $event"
      ></group-project-list>
    </keep-alive>
    <delete-modal
      v-if="showDeleteModal"
      :resourceId="item.uid"
      :resourceName="item.name"
      :resourceType="'group'"
      :resourceService="groupService"
      @closed="showDeleteModal = false"
      @deleted="$emit('refresh-group-list')"
    />
  </div>
</template>

<script>
  import MainDetailsMedia from "@/app/old/desktop/shared/components/main-details-media";
  import PrimaryTag from "@/app/old/desktop/shared/components/tags/primary-tag";
  import GroupProjectList from "../components/group-project-list";
  import { groupService } from "@/app/old/desktop/shared/services/";

  export default {
    props: ["item", "groupService", "owner"],
    components: {
      MainDetailsMedia,
      PrimaryTag,
      GroupProjectList
    },
    data() {
      return {
        show_projects: false,
        hover: false,
        cal_active: false,
        properties: {},
        calendar: null,
        dropdown: false
      };
    },
    methods: {
      close_dropdown(uid) {
        if (this.$refs[uid] && this.$refs[uid].active) {
          this.hover = true;
        } else {
          this.hover = false;
        }
      },
      create_cal() {
        if (!this.calendar) {
          this.calendar = new dhx.Calendar(
            `${"group_list_cal-" + this.item.uid}`,
            {
              css: "projects-cal",
              value:
                this.item && this.item.properties && this.item.properties.date
                  ? new Date(this.item.properties.date)
                  : new Date()
            }
          );
        }
        this.calendar.events.on("Change", date => {
          this.change_duedate(date);
        });
      },
      change_duedate(date) {
        this.properties.date = date;
        this.update_properties(this.item);
        this.cal_active = false;
        this.calendar = null;
      },
      remove_date() {
        this.properties.date = null;
        this.update_properties(this.item);
      },

      async update_properties(project) {
        this.properties.is_disabled = this.group_disabled;
        let result = await groupService
          .patch({
            id: project.uid,
            body: { properties: this.properties }
          })
          .catch(err => this.handleErrors(err, true));
        if (result) {
          this.cal_active ? (this.cal_active = false) : null;
        }
        this.$emit("refresh-group-list", false);
      }
    }
  };
</script>
<style lang="scss">
  .show-project-icon {
    position: absolute;
    left: -2.5rem;
    min-height: 6rem;
    display: flex;
    align-items: center;
    color: #5c617e;
    cursor: pointer;
  }
</style>
