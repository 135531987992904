<template>
  <div>
    <!-- <pre>
          {{ asset }}
      </pre
    > -->
    <!-- <input type="text" v-model="form.name" />
    <button @click="create_new_ticket">
      save
    </button> -->
    <div class="asset-task">
      <div class="asset-task__card">
        <sh-icon
          :name="'component'"
          :file="'system-model-sprite'"
          class="is-70x70 mb-10"
        ></sh-icon>
        <div class="pb-8 is-semiBold-18-600-22">
          Create Task
        </div>
        <div
          class="asset-task__card--desc is-regular-14-500-17 has-text-theme-grey"
        >
          Create task for the selected asset code
        </div>
        <div class="new-task" v-if="new_task_input">
          <input
            v-focus
            type="text"
            class="new-task-input is-medium-14-500-17"
            placeholder="Enter Task Name"
            v-model="form.name"
            @keyup.enter="
              new_task_input = false;
              create_new_ticket();
            "
            @keyup.esc="new_task_input = false"
          />
          <div
            class="new-task-check-btn"
            @click="
              new_task_input = false;
              create_new_ticket();
            "
          >
            <sh-icon
              :name="'check-icon-white'"
              :file="'system-model-sprite'"
              class="is-18x18"
            ></sh-icon>
          </div>
        </div>
        <div
          @click="new_task_input = true"
          v-else
          class="new-task-btn is-semiBold-14-600-17"
        >
          Create
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { mapState } from "vuex";
  import {
    ticketService,
    assetService
  } from "@/app/old/desktop/shared/services/";
  import elementMixin from "@/app/old/desktop/shared/mixins/element.mixin";
  import SegmentAnalytics from "@/app/old/desktop/shared/mixins/segment-analytics.mixin.vue";
  import TicketDetailsMixin from "@/app/old/desktop/shared/mixins/ticket-details.mixin.vue";

  export default {
    mixins: [elementMixin, TicketDetailsMixin, SegmentAnalytics],
    data() {
      return {
        form: {
          name: "",
          priority: 4,
          status: 1,
          takenUp: null,
          dueDate: null,
          startDate: null
        },
        is_creating: false,
        asset: null,
        new_task_input: false,
        element: null,
        targetElement: null
      };
    },
    computed: {
      ...mapState({
        is_internal_resource: state => state.is_internal_resource,
        global_asset: state => state.selected_asset
      }),
      ...mapState("tickets", ["is_internal"])
    },
    created() {
      this.getAsset();
      if (this.$route.query?.name) {
        this.form.name = this.$route.query.name;
      }
    },
    methods: {
      async getAsset() {
        if (this.$route.query?.asset_code) {
          let { results } = await assetService.getAll({
            query: `code=${this.$route.query.asset_code}`
          });

          this.asset = results[0];
          this.element = this.asset.element;
          this.getElement();
        }
      },

      async getElement() {
        if (this.element) {
          await this.create_or_get_element(this.element);

          this.targetElement = this.get_target_element(this.element);
        } else {
          let org_element = this.$store.state.org_element;
          this.targetElement = this.get_target_element(org_element);
        }
      },

      async create_new_ticket() {
        if (this.form.name) {
          this.form.name = this.form.name.trim();
          this.is_saving = true;
        }

        if (this.is_creating || !this.form.name) return;
        this.is_creating = true;

        if (!this.targetElement) return;

        this.form.targetElement = this.targetElement;

        this.form.internal = this.is_internal;

        if (this.element && this.element.centroid)
          this.form["location"] = {
            type: "Point",
            coordinates: Object.values(this.element.centroid)
          };

        if (this.element && this.element.dataUid) {
          this.form.dataUid = this.element.dataUid;
        }
        if (this.$route.query?.attached_to) {
          this.form["properties"] = {
            reference_name: this.$route.query.attached_to
          };
        }
        let new_ticket_data = { ...this.form };
        // let ticket_data = await this.$store.dispatch(
        //   "tickets/add_new_ticket",
        //   new_ticket_data
        // );
        let { data } = await ticketService
          .post({ body: new_ticket_data })
          .catch(err => {
            // this.handleErrors(err);
            console.log(err);
          });
        let ticket_data = data;
        const set_attributes = [];
        for (let attribute in this.form) {
          if (this.form[attribute]) {
            set_attributes.push(attribute);
          }
        }
        this.segment_track_event("ticket_created", {
          attributes: set_attributes,
          from: this.ticket_created_from || "unknown"
        });
        if (this.$store.getters.open_task_on_create) {
          this.open_ticket_details(ticket_data.uid);
        }
        this.$router.push({ name: "tickets" });

        (this.form.name = null),
          (this.form.priority = 4),
          (this.form.status = 1),
          (this.form.takenUp = null),
          (this.form.dueDate = null),
          (this.form.startDate = null),
          (this.is_creating = false);

        setTimeout(() => {
          this.is_saving = false;
        }, 200);
      }
    }
  };
</script>
<style lang="scss" scoped>
  .asset-task {
    height: 85vh;
    display: flex;
    align-items: center;
    justify-content: center;
    &__card {
      background-color: #fff;
      width: 44rem;
      height: 28rem;
      box-shadow: 0px 0px 20px #0000001a;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      &--desc {
        padding: 0 8rem 2rem;
      }
    }
  }
  .new-task {
    display: flex;
    align-items: center;
    input::placeholder {
      opacity: 0.5;
    }
    &-input {
      width: 21.8rem;
      height: 3.6rem;
      border: 1px solid #171e484d;
      border-radius: 4px;
      padding: 0.9rem 1.2rem;
      margin-right: 10px;
    }
    &-check-btn {
      height: 3.6rem;
      width: 3.6rem;
      background: #2585d3;
      border: 1px solid #2585d3;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    &-btn {
      height: 3.6rem;
      background-color: $primary;
      color: #fff;
      border-radius: 0.4rem;
      display: flex;
      align-items: center;
      padding: 0 3rem;
      box-shadow: 0px 1px 3px #2585d333;
      cursor: pointer;
    }
  }
</style>
